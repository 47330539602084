import { all, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { checkSearchParams } from 'Helpers';

// TYPES
import { Types as FlowTypes } from 'Reducers/flow';
import { Types as UserTypes } from 'Reducers/user';
import { Types as GrowlTypes } from 'Reducers/growl';
import { Types as ResultTypes } from 'Reducers/result';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { Types as CampaignTypes } from 'Reducers/campaign';
import { Types as PostCodesTypes } from 'Reducers/postCodes';
import { Types as ApplicationTypes, mode as modeSelector } from 'Reducers/application';

// SAGAS
import {
  apiError,
  checkOnline,
  backendLoginError,
  backendLoginSuccessful,
  brokenOrUnauthorizedAccess,
  checkDonorLoginCompleted,
  clearSession,
  closeSplashScreen,
  donorLogin,
  donorLoginCompleted,
  donorLoginError,
  donorLoginSuccessful,
  initFrameworkSeven,
  login,
  logout,
  navigateTo,
  onOrientationChange,
  persisted,
  securityLogout,
  sessionExpiredLogout,
  userIsOnline
} from 'Sagas/application';

import { get as getCampaigns, getError as getCampaignsError, setCampaignsAndDefault } from 'Sagas/campaign';

import {
  checkIn,
  getUKAddress,
  getUKAddressSuccess,
  getUKAddressFail,
  checkOut,
  get as getCheckIns,
  set as setCheckIns
} from 'Sagas/checkIn';

import {
  checkOfflineFlows,
  forceUpdatePageComponents,
  get as getFlow,
  getDonorFlow,
  getError as getFlowError,
  initFlowMetadata,
  initSubmitFlow,
  navigateToFirstInvalidatedPage,
  nextFlowPage,
  previousFlowPage,
  runComponentLogicRules,
  runPageLogicRules,
  runPageValidations,
  saveFlowForOffline,
  setDonorFlow,
  submitFlow,
  submitFlowError,
  submitFlowSuccess,
  submitOfflineFlow,
  submitOfflineFlowSuccess,
  validateOnlineBankAccount,
  validateOnlineBankAccountSuccess,
  validateOnlineBankAccountError
} from 'Sagas/flow';

import { alert } from 'Sagas/growl';

import { get as getResults, getError as getResultsError } from 'Sagas/result';

import { setCampaign as setCampaignOnUser, updateUser } from 'Sagas/user';

export default function* root() {
  const mode = yield select(modeSelector);

  const donorEffects = [
    takeLatest(ApplicationTypes.DONOR_LOGIN, donorLogin),
    takeLatest(ApplicationTypes.DONOR_LOGIN_ERROR, donorLoginError),
    takeLatest(ApplicationTypes.DONOR_LOGIN_COMPLETED, donorLoginCompleted),
    takeLatest(ApplicationTypes.DONOR_LOGIN_SUCCESSFUL, donorLoginSuccessful),

    takeLatest(FlowTypes.GET_DONOR_FLOW, getDonorFlow),
    takeLatest(FlowTypes.SET_DONOR_FLOW, setDonorFlow),

    takeLatest(FlowTypes.SET_DONOR_FLOW, checkDonorLoginCompleted),

    takeLatest(PostCodesTypes.SET_DONOR_ADDRESSES, checkDonorLoginCompleted)
  ];

  const mainEffects = [
    takeEvery('API_ERROR', apiError),
    takeLatest(ApplicationTypes.CHECK_ONLINE, checkOnline),
    takeLatest(ApplicationTypes.BACKEND_LOGIN_ERROR, backendLoginError),
    takeLatest(ApplicationTypes.BACKEND_LOGIN_SUCCESSFUL, backendLoginSuccessful),
    takeLatest(ApplicationTypes.BROKEN_OR_UNAUTHORIZED_ACCESS, brokenOrUnauthorizedAccess),
    takeLatest(ApplicationTypes.CLEAR_SESSION, clearSession),
    takeLatest(ApplicationTypes.CLOSE_SPLASH_SCREEN, closeSplashScreen),
    takeLatest(ApplicationTypes.INIT_FRAMEWORK_SEVEN, initFrameworkSeven),
    takeLatest(ApplicationTypes.LOGIN, login),
    takeLatest(ApplicationTypes.LOGOUT, logout),
    takeLatest(ApplicationTypes.NAVIGATE_TO, navigateTo),
    takeLatest(ApplicationTypes.ON_ORIENTATION_CHANGE, onOrientationChange),
    takeLatest(ApplicationTypes.PERSISTED, persisted),
    takeLatest(ApplicationTypes.SECURITY_LOGOUT, securityLogout),
    takeLatest(ApplicationTypes.SESSION_EXPIRED_LOGOUT, sessionExpiredLogout),
    takeLatest(ApplicationTypes.USER_IS_ONLINE, userIsOnline),

    takeLatest(CheckInTypes.CHECK_IN, checkIn),
    takeLatest(CheckInTypes.GET_UK_ADDRESS, getUKAddress),
    takeLatest(CheckInTypes.GET_UK_ADDRESS_SUCCESS, getUKAddressSuccess),
    takeLatest(CheckInTypes.GET_UK_ADDRESS_FAIL, getUKAddressFail),
    takeLatest(CheckInTypes.CHECK_OUT, checkOut),
    takeLatest(CheckInTypes.GET, getCheckIns),
    takeLatest(CheckInTypes.SET, setCheckIns),

    takeLatest(CampaignTypes.GET, getCampaigns),
    takeLatest(CampaignTypes.GET_ERROR, getCampaignsError),
    takeLatest(CampaignTypes.SET_CAMPAIGNS_AND_DEFAULT, setCampaignsAndDefault),

    takeLatest(FlowTypes.CHECK_OFFLINE_FLOWS, checkOfflineFlows),
    takeLatest(FlowTypes.FORCE_UPDATE_PAGE_COMPONENTS, forceUpdatePageComponents),
    takeLatest(FlowTypes.GET, getFlow),
    takeLatest(FlowTypes.GET_ERROR, getFlowError),
    takeLatest(FlowTypes.INIT_FLOW_METADATA, initFlowMetadata),
    takeLatest(FlowTypes.INIT_SUBMIT_FLOW, initSubmitFlow),
    takeLatest(FlowTypes.INVALIDATE_COMPONENT, runPageValidations),
    takeLatest(FlowTypes.NAVIGATE_TO_FIRST_INVALIDATED_PAGE, navigateToFirstInvalidatedPage),
    takeLatest(FlowTypes.NEXT_FLOW_PAGE, nextFlowPage),
    takeLatest(FlowTypes.PREVIOUS_FLOW_PAGE, previousFlowPage),
    takeLatest(FlowTypes.RUN_PAGE_LOGIC_RULES, runPageLogicRules),
    takeLatest(FlowTypes.RUN_PAGE_VALIDATIONS, runPageValidations),
    takeLatest(FlowTypes.SAVE_FLOW_FOR_OFFLINE, saveFlowForOffline),
    takeLatest(FlowTypes.SUBMIT_FLOW, submitFlow),
    takeLatest(FlowTypes.SUBMIT_FLOW_ERROR, submitFlowError),
    takeLatest(FlowTypes.SUBMIT_FLOW_SUCCESS, submitFlowSuccess),
    takeLatest(FlowTypes.SUBMIT_OFFLINE_FLOW, submitOfflineFlow),
    takeLatest(FlowTypes.SUBMIT_OFFLINE_FLOW_SUCCESS, submitOfflineFlowSuccess),
    takeEvery(FlowTypes.UPDATE_CURRENT_FLOW_DATA, runComponentLogicRules),
    takeLatest(FlowTypes.VALIDATE_COMPONENT, runPageValidations),
    takeLatest(FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT, validateOnlineBankAccount),
    takeLatest(FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT_SUCCESS, validateOnlineBankAccountSuccess),
    takeLatest(FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT_ERROR, validateOnlineBankAccountError),

    takeLatest(GrowlTypes.ALERT, alert),

    takeLatest(ResultTypes.GET, getResults),
    takeLatest(ResultTypes.GET_ERROR, getResultsError),

    takeLatest(UserTypes.SET_CAMPAIGN, setCampaignOnUser),
    takeLatest(UserTypes.UPDATE_API_MODEL, updateUser)
  ];

  const { isDonor } = checkSearchParams(window.location.search);
  yield all([...mainEffects, ...(mode === 'donor' || isDonor ? donorEffects : [])]);
}
