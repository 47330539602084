import React from 'react';
import PropTypes from 'prop-types';
import { Page, Link, Block } from 'framework7-react';

import { NavigationService } from 'Services';

const ForgotPassword = () => (
  <Page>
    <Block strong>
      <p>Forgot password page</p>
    </Block>
    <Link onClick={NavigationService.goBack}>Go back</Link>
  </Page>
);

ForgotPassword.propTypes = {
  login: PropTypes.func
};

export default ForgotPassword;
