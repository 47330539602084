import { put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { Campaign, User } from 'Repositories';
import { Types as CampaignTypes } from 'Reducers/campaign';
import { user as userSelector, Types as UserTypes } from 'Reducers/user';

// EXPORTED
export const setCampaign = function*({ campaign }) {
  const user = yield select(userSelector);

  yield put({
    type: UserTypes.UPDATE_API_MODEL,
    props: { campaign: { type: 'campaign', id: campaign.id } }
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CampaignTypes.SET_SELECTED_CAMPAIGN, userId: user.id },
      fail: { type: Api.API_ERROR }
    },
    promise: Campaign.getCampaign(campaign.id)
  });
};

export const updateUser = function*() {
  const user = yield select(userSelector);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.SET_USER },
      fail: { type: Api.API_ERROR }
    },
    promise: User.updateUser(user)
  });
};
