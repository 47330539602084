import { put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { I18n } from 'Locales';
import { Campaign, PostCodes } from 'Repositories';
import { Types as GrowlTypes } from 'Reducers/growl';
import { Types as CampaignTypes } from 'Reducers/campaign';
import { Types as PostCodesTypes } from 'Reducers/postCodes';
import { user as userSelector, Types as UserTypes } from 'Reducers/user';

// EXPORTED
export const get = function*() {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CampaignTypes.SET_CAMPAIGNS_AND_DEFAULT },
      fail: { type: CampaignTypes.GET_ERROR }
    },
    promise: Campaign.get()
  });
};

export const setCampaignsAndDefault = function*({ payload }) {
  const user = yield select(userSelector);
  let selectedCampaignId = payload[0].id;

  yield put({ type: CampaignTypes.SET, payload, userId: user.id });

  if (user.campaign === null) {
    yield put({ type: UserTypes.SET_CAMPAIGN, campaign: payload[0] });
  } else {
    selectedCampaignId = user.campaign.id;
  }

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CampaignTypes.SET_SELECTED_CAMPAIGN, userId: user.id },
      fail: { type: Api.API_ERROR }
    },
    promise: Campaign.getCampaign(selectedCampaignId)
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: PostCodesTypes.SET },
      fail: { type: Api.API_ERROR }
    },
    promise: PostCodes.get()
  });
};

export const getError = function*() {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.campaign.get.title'),
    body: I18n.t('growl:error.campaign.get.body'),
    kind: 'error'
  });
};
