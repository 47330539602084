import { connect } from 'react-redux';

import Config from 'Config';
import Campaigns from './view';
import * as Selectors from 'Selectors';

import { Creators as checkInActions } from 'Reducers/checkIn';

export default connect(
  state => ({
    availableCampaign: Config.ALLOW_CAMPAIGN_ID,
    badgeNumber: Selectors.validBadgeNumber(state),
    checkInObject: Selectors.checkInObject(state),
    flowId: Selectors.flowId(state),
    flowReady: Selectors.flowReady(state),
    hasValidBadge: Selectors.hasValidBadge(state),
    isCheckedIn: Selectors.isCheckedIn(state),
    user: state.user
  }),
  {
    checkOut: checkInActions.checkOut
  }
)(Campaigns);
