import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListInput } from 'framework7-react';

import { I18n } from 'Locales';
import { isSmallDevice } from 'Helpers';
import { ColumnView, RowView } from 'Containers';

import './style.scss';
import { bankComponentOnlineFields, getFieldProp } from '../../../shared';

class BankAccountUK extends Component {
  constructor(props) {
    super(props);
    this.accountNumberProp = getFieldProp(this.props.fields, 'accountNumber');
    this.bankNameProp = getFieldProp(this.props.fields, 'bankName');
    this.holderNameProp = getFieldProp(this.props.fields, 'holderName');
    this.sortCodeProp = getFieldProp(this.props.fields, 'sortCode');
  }

  componentDidUpdate = prevProps => {
    const { forceUpdate, value: bankAccountValues } = this.props;
    if (forceUpdate !== prevProps.forceUpdate) {
      for (const bankComponentOnlineField of bankComponentOnlineFields) {
        if (bankAccountValues[bankComponentOnlineField]?.length > 0) {
          this.setValues(bankAccountValues, {
            [bankComponentOnlineField]: bankAccountValues[bankComponentOnlineField]
          });
          return true;
        }
      }
    }
  };

  setValues = (oldProps, individualKeyValue) => {
    const initialState = {
      bankName: '',
      holderName: '',
      accountNumber: '',
      sortCode: '',
      validationStatus: '',
      validationMessage: ''
    };

    const newState = Object.assign(initialState, oldProps, individualKeyValue);

    this.props.updateValue({ value: newState, updatingKey: Object.keys(individualKeyValue)[0] });
  };

  render = () => {
    const { accountNumberProp, bankNameProp, holderNameProp, sortCodeProp, props } = this;
    const { shouldShowError, value } = props;

    let bankAccountValues = value;
    if (!bankAccountValues) {
      bankAccountValues = {
        bankName: '',
        holderName: '',
        accountNumber: '',
        sortCode: '',
        validationStatus: '',
        validationMessage: ''
      };
    }

    const { bankName, holderName, accountNumber, sortCode } = bankAccountValues;

    return (
      <ColumnView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.bankAccount.bankName')}
            type={'text'}
            placeholder={bankNameProp?.placeholder}
            className={'input-style'}
            value={bankName}
            onChange={e => this.setValues(value, { bankName: e.target.value })}
          />
        </RowView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.bankAccount.holderName')}
            type={'text'}
            placeholder={holderNameProp?.placeholder}
            className={'input-style'}
            value={holderName}
            onChange={e => this.setValues(value, { holderName: e.target.value })}
            errorMessageForce={shouldShowError}
            errorMessage={I18n.t('flow:generalError')}
          />
        </RowView>

        <RowView flexWrap={isSmallDevice ? 'wrap' : 'nowrap'}>
          <ListInput
            label={I18n.t('flow:components.bankAccount.accountNumber')}
            type={'text'}
            placeholder={accountNumberProp?.placeholder}
            className={'input-style'}
            value={accountNumber}
            onChange={e => this.setValues(value, { accountNumber: e.target.value })}
            errorMessageForce={shouldShowError}
            errorMessage={I18n.t('flow:generalError')}
          />

          <ListInput
            label={I18n.t('flow:components.bankAccount.sortCode')}
            type={'text'}
            placeholder={sortCodeProp?.placeholder}
            className={'input-style'}
            value={sortCode}
            onChange={e => this.setValues(value, { sortCode: e.target.value })}
            errorMessageForce={shouldShowError}
            errorMessage={I18n.t('flow:generalError')}
          />
        </RowView>
      </ColumnView>
    );
  };
}

BankAccountUK.propTypes = {
  fields: PropTypes.array,
  forceUpdate: PropTypes.number,
  updateValue: PropTypes.func,
  value: PropTypes.any,
  shouldShowError: PropTypes.any
};

export default BankAccountUK;
