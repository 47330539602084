import { connect } from 'react-redux';

import { Creators as applicationActions } from 'Reducers/application';
import { Creators as userActions } from 'Reducers/user';

import * as Selectors from 'Selectors';

import AppLeftPanel from './view';

export default connect(
  state => ({
    user: state.user,
    campaigns: Selectors.userCampaigns(state),
    selectedCampaign: state.application.selectedCampaign,
    serviceWorkerVersion: state.application.serviceWorkerVersion
  }),
  {
    logout: applicationActions.logout,
    setUserCampaign: userActions.setCampaign,
    updateApplicationProp: applicationActions.updateProp
  }
)(AppLeftPanel);
