import Config from 'Config';

const generateComponentProperties = properties => {
  let required = false;
  let showInSummaryPage = false;
  let showExtraInfo2 = false;
  let max_length = null;

  for (let i = 0; i < properties.length; i++) {
    const { property, value } = properties[i];
    if (property === 'required') {
      required = stringToBool(value);
    }

    if (property === 'show_in_summary_page') {
      showInSummaryPage = stringToBool(value);
    }

    if (property === 'max_length') {
      max_length = parseInt(value) || null;
    }

    if (property === 'show_extra_info_2') {
      showExtraInfo2 = stringToBool(value);
    }
  }

  return { max_length, required, showInSummaryPage, showExtraInfo2 };
};

const getAge = birthDateString => {
  const today = new Date();
  const birthDate = new Date(birthDateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const checkSearchParams = search => {
  const params = new URLSearchParams(search.slice(1));
  return {
    ba: params.get('ba'),
    client: params.get('client'),
    isDonor: params.has('donor'),
    orID: params.get('token'),
    country: params.get('country')
  };
};

const idFilter = (choice, rf) => choice === rf.id;

const isSmallDevice = Config.SMALL_DEVICE_WIDTH > window.innerWidth;

const isObjEmpty = obj => Object.entries(obj).length === 0;

const ibanMaskConfig = iban => {
  const groupSize = 4;
  return iban
    ?.replace(/[^a-zA-Z0-9]/g, '')
    ?.replace(new RegExp(`(.{${groupSize}})(?!$)`, 'g'), '$1 ')
    ?.trim();
};

const isValidIBAN = iban => {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26
  };
  iban = iban.toUpperCase().replace(/[^A-Z0-9]/g, '');
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  if (mod97(digits) !== 1) {
    return false;
  }
  return true;
};

const mod97 = digits => {
  let checksum = digits.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < digits.length; offset += 7) {
    fragment = `${checksum}${digits.substring(offset, offset + 7)}`;
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
};

const mockFunction = () => {};

const regularPagesOnly = page => page.page_type === 'regular';

const stringToBool = val => val == 'true';

const sum = (a, b) => a + b;

const thankYouPageOnly = page => page.page_type === 'thank_you';

export {
  checkSearchParams,
  idFilter,
  isSmallDevice,
  isObjEmpty,
  ibanMaskConfig,
  isValidIBAN,
  generateComponentProperties,
  getAge,
  mockFunction,
  regularPagesOnly,
  sum,
  thankYouPageOnly
};
