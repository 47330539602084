import { createActions, createReducer } from 'reduxsauce';

import { regularPagesOnly } from 'Helpers';
import { updateProp, updateProps } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    checkOfflineFlows: null,
    forceUpdatePageComponents: null,
    get: ['id', 'checkInId'],
    getDonorFlow: ['id'],
    getError: ['checkInId'],
    initFlowMetadata: ['id'],
    initSubmitFlow: null,
    invalidateComponent: ['pageId', 'componentKey'],
    invalidatePage: ['pageId'],
    navigateToFirstInvalidatedPage: null,
    nextFlowPage: null,
    previousFlowPage: null,
    registerLogicRule: [
      'action',
      'componentExtras',
      'componentId',
      'fieldId',
      'fieldName',
      'id',
      'logicType',
      'predicate',
      'value'
    ],
    runPageLogicRules: ['value'],
    runPageValidations: ['action'],
    saveFlowForOffline: ['data'],
    set: ['payload'],
    setDonorFlow: ['payload'],
    showComponent: ['id'],
    stripComponent: ['id'],
    stripPages: ['pages'],
    tryExecutionToken: ['offlineFlow'],
    submitFlow: ['payload'],
    submitFlowSuccess: ['payload'],
    submitFlowError: ['data'],
    submitOfflineFlow: ['payload', 'offlineFlow'],
    submitOfflineFlowSuccess: ['userId', 'offlineFlow'],
    updateAgreement: null,
    updateBankComponentFlowData: ['key', 'value', 'isValid'],
    updateCurrentFlowData: ['key', 'value'],
    updateProp: ['key', 'value'],
    updateProps: ['props'],
    validateComponent: ['pageId', 'componentKey'],
    validateOnlineBankAccount: ['component', 'pageId', 'updateValues'],
    validateOnlineBankAccountSuccess: ['component', 'updateValues'],
    validateOnlineBankAccountError: ['component', 'updateValues'],
    validatePage: ['pageId']
  },
  { prefix: 'flow/' }
);

const defaultState = {
  availablePages: [],
  currentFlowData: {},
  currentFlowIndex: null,
  currentFlowPageLogicRules: {},
  currentFlowComponentLogicRules: {},
  currentFlowValidations: {},
  currentPage: null,
  currentPageIndex: 0,
  flowPages: [],
  flows: {},
  pageIsInvalidOnNext: false,
  signature: {
    base64: '',
    data: '',
    agreement: false
  },
  validatedPages: []
};

const initialState = {
  ...defaultState,
  flows: {}
};

const processingFalse = state => ({
  ...state,
  processing: false
});

const processingTrue = state => ({
  ...state,
  processing: true
});

export const initFlowMetadata = (state, { id }) => ({
  ...defaultState,
  flows: state.flows,
  currentFlowIndex: id,
  timeStartedFlow: Date.now()
});

export const invalidateComponent = (oldState, { pageId, componentKey }) => {
  const state = { ...oldState };
  if (!state.currentFlowValidations[pageId]) {
    state.currentFlowValidations[pageId] = [componentKey];
  } else if (!state.currentFlowValidations[pageId].includes(componentKey)) {
    state.currentFlowValidations[pageId].push(componentKey);
  }
  return { ...state };
};

export const invalidatePage = (state, { pageId }) => ({
  ...state,
  validatedPages: [...state.validatedPages.filter(validPage => validPage !== pageId)]
});

export const registerLogicRule = (
  oldState,
  { logicType = 'page', id, action, predicate, value, componentId, fieldId, fieldName, componentExtras }
) => {
  const state = { ...oldState };
  if (logicType === 'page') {
    state.currentFlowPageLogicRules[id] = {
      componentId,
      fieldId,
      action,
      predicate,
      value
    };
  } else {
    if (!state.currentFlowComponentLogicRules[id]) {
      state.currentFlowComponentLogicRules[id] = [];
    }
    state.currentFlowComponentLogicRules[id].push({
      componentId,
      fieldId,
      fieldName,
      action,
      predicate,
      value,
      componentExtras
    });
  }
  return { ...state };
};

export const set = (state, { payload, applicationMode = 'donor' }) => {
  if (payload && payload.id) {
    let visiblePages = payload.flow_pages;
    if (applicationMode === 'donor') {
      visiblePages = payload.flow_pages.filter(page => !page.hide_for_donor);
    }

    const updatedFlows = {
      ...state.flows,
      [payload.id]: {
        ...payload,
        flow_pages: visiblePages
      }
    };

    return {
      ...state,
      flows: updatedFlows
    };
  } else {
    return { ...state };
  }
};

export const showComponent = (oldState, { id }) => {
  const state = { ...oldState };
  if (state.currentFlowData[id]) {
    state.currentFlowData[id] = {
      ...state.currentFlowData[id],
      visible: true
    };
  } else {
    state.currentFlowData[id] = {
      visible: true
    };
  }

  return { ...state };
};

export const stripComponent = (oldState, { id }) => {
  const state = { ...oldState };
  delete state.currentFlowData[id];
  return { ...state };
};

export const stripPages = (oldState, { pages }) => {
  const state = { ...oldState };
  const flowPages = state.flows[state.currentFlowIndex].flow_pages.filter(regularPagesOnly);
  for (const page of flowPages) {
    if (pages.includes(page.id)) {
      for (const { component_key } of page.components) {
        if (state.currentFlowData[component_key]) {
          delete state.currentFlowData[component_key];
        }
      }
    }
  }
  return { ...state };
};

export const updateAgreement = state => ({
  ...state,
  signature: {
    ...state.signature,
    agreement: !state.signature.agreement
  }
});

export const updateBankComponentFlowData = (state, { key, value, isValid }) => ({
  ...state,
  currentFlowData: {
    ...state.currentFlowData,
    [key]: { value: { ...state.currentFlowData[key].value, ...value }, visible: true, isValid }
  }
});

export const updateCurrentFlowData = (state, { key, value }) => ({
  ...state,
  currentFlowData: {
    ...state.currentFlowData,
    [key]: { ...value, visible: true }
  }
});

export const validateComponent = (state, { pageId, componentKey }) => {
  const newCurrentFlowValidations = state.currentFlowValidations;
  if (newCurrentFlowValidations[pageId]) {
    newCurrentFlowValidations[pageId] = newCurrentFlowValidations[pageId].filter(
      invalidComponent => invalidComponent !== componentKey
    );
  }

  return {
    ...state,
    currentFlowValidations: newCurrentFlowValidations
  };
};

export const validatePage = (oldState, { pageId }) => {
  const state = { ...oldState };
  if (!state.validatedPages.includes(pageId)) {
    state.validatedPages.push(pageId);
  }
  return { ...state };
};

export default createReducer(initialState, {
  [Types.INIT_FLOW_METADATA]: initFlowMetadata,
  [Types.INIT_SUBMIT_FLOW]: processingTrue,
  [Types.INVALIDATE_COMPONENT]: invalidateComponent,
  [Types.INVALIDATE_PAGE]: invalidatePage,
  [Types.REGISTER_LOGIC_RULE]: registerLogicRule,
  [Types.SAVE_FLOW_FOR_OFFLINE]: processingFalse,
  [Types.SET]: set,
  [Types.SET_DONOR_FLOW]: set,
  [Types.SHOW_COMPONENT]: showComponent,
  [Types.STRIP_COMPONENT]: stripComponent,
  [Types.STRIP_PAGES]: stripPages,
  [Types.SUBMIT_FLOW_ERROR]: processingFalse,
  [Types.SUBMIT_FLOW_SUCCESS]: processingFalse,
  [Types.UPDATE_AGREEMENT]: updateAgreement,
  [Types.UPDATE_BANK_COMPONENT_FLOW_DATA]: updateBankComponentFlowData,
  [Types.UPDATE_CURRENT_FLOW_DATA]: updateCurrentFlowData,
  [Types.UPDATE_PROP]: updateProp,
  [Types.UPDATE_PROPS]: updateProps,
  [Types.VALIDATE_COMPONENT]: validateComponent,
  [Types.VALIDATE_PAGE]: validatePage
});

export const availablePages = state => state.flow.availablePages;
export const currentFlow = state => state.flow.flows[state.flow.currentFlowIndex];
export const currentFlowData = state => state.flow.currentFlowData;
export const currentFlowComponentLogicRules = state => state.flow.currentFlowComponentLogicRules;
export const currentFlowPageLogicRules = state => state.flow.currentFlowPageLogicRules;
export const currentFlowValidations = state => state.flow.currentFlowValidations;
export const currentPageIndex = state => state.flow.currentPageIndex;
export const flows = state => state.flow.flows;
export const flowPages = state => state.flow.flowPages;
export const flowSignature = state => state.flow.signature;
export const timeStartedFlow = state => state.flow.timeStartedFlow;
export const validatedPages = state => state.flow.validatedPages;
