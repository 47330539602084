import { call, delay, put, select } from 'redux-saga/effects';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

import { Api } from 'Core';
import Config from 'Config';
import { I18n } from 'Locales';
import { User } from 'Repositories';
import { ServiceWorker } from 'Offline';
import { checkSearchParams } from 'Helpers';
import { defaultRoutes } from 'Core/routes';
import { Types as GrowlTypes } from 'Reducers/growl';
import { Types as StateTypes } from 'Redux/rootReducer';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { NavigationService, PwaService } from 'Services';
import { Types as TransientTypes } from 'Reducers/transient';
import { Types as FlowTypes, flows as flowsSelector } from 'Reducers/flow';
import { donorAddresses as donorAddressesSelector } from 'Reducers/postCodes';
import { Types as UserTypes, firstName as firstNameSelector, donorFlowId as donorFlowIdSelector } from 'Reducers/user';
import {
  Types as ApplicationTypes,
  ba as baSelector,
  client as clientSelector,
  isOffline as isOfflineSelector,
  mode as modeSelector,
  offlineSince as offlineSinceSelector,
  orID as orIDSelector,
  session as sessionSelect,
  token as tokenSelect
} from 'Reducers/application';

import { onOnline } from './onOnline';
import { onOffline } from './onOffline';
import { navigateTo as navigateToExport } from './navigateTo';
import { onServiceWorkerMessage } from './onServiceWorkerMessage';
import { orientationChangeDetected, onOrientationChange as onOrientationChangeExported } from './onOrientationChange';

// EXPORTED
export const apiError = function* ({ error }) {
  switch (error.status) {
    case 401:
    case 403:
      yield put({ type: ApplicationTypes.SESSION_EXPIRED_LOGOUT });
      break;

    case '0':
    case 'TIMEOUT':
      yield put({
        type: GrowlTypes.ALERT,
        kind: 'error',
        title: I18n.t('growl:error.backend.timeout.title'),
        body: I18n.t('growl:error.backend.timeout.body')
      });
      break;
  }
};

export const backendLoginError = function* () {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      error: true,
      processing: false,
      password: ''
    }
  });

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.login.title'),
    body: I18n.t('growl:error.login.body'),
    kind: 'error'
  });

  yield delay(1000);

  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'error',
    value: false
  });
};

export const backendLoginSuccessful = function* ({ payload: user }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      processing: false
    }
  });
  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      session: true,
      token: AES.encrypt(user.auth_token, Config.PASSPHRASE).toString()
    }
  });

  yield put({
    type: UserTypes.SET_USER,
    payload: user
  });

  yield put({
    type: ApplicationTypes.SAVE_USER_PUBLIC_INFO,
    email: user.email,
    avatar: user.avatar_url
  });

  yield put({
    type: CheckInTypes.GET
  });

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:success.login.title', { first_name: user.first_name }),
    body: I18n.t('growl:success.login.body'),
    kind: 'info'
  });

  NavigationService.navigate({
    name: defaultRoutes.session.name
  });
};

export const brokenOrUnauthorizedAccess = function () {
  NavigationService.navigate({
    name: 'BrokenOrUnauthorized'
  });
};

export const checkDonorLoginCompleted = function* () {
  const flows = yield select(flowsSelector);
  const donorAddresses = yield select(donorAddressesSelector);
  const donorFlowId = yield select(donorFlowIdSelector);
  const flow = flows[donorFlowId];

  if (flows && flow && (donorAddresses?.length > 0 || ['BE', 'DE', 'IE', 'UK'].includes(flow.country))) {
    yield put({ type: ApplicationTypes.DONOR_LOGIN_COMPLETED, flowId: donorFlowId });
  }
};

export const checkOnline = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.USER_IS_ONLINE }
    },
    promise: User.getExecutionToken()
  });
};

export const clearSession = function* () {
  NavigationService.navigate({
    name: defaultRoutes.noSession.name
  });
  Api.auth_token();
  yield put({ type: StateTypes.RESET });
};

export const closeSplashScreen = function* () {
  yield delay(300);
  yield put({
    type: ApplicationTypes.UPDATE_PROP,
    key: 'initialised',
    value: true
  });
};

export const donorLogin = function* ({ badge_number, orID }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      enableDonorLogin: false,
      processing: true
    }
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.DONOR_LOGIN_SUCCESSFUL },
      fail: { type: ApplicationTypes.DONOR_LOGIN_ERROR }
    },
    promise: User.donorLogin(badge_number, orID)
  });
};

export const donorLoginCompleted = function* ({ flowId }) {
  const client = yield select(clientSelector);
  const ba = yield select(baSelector);

  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      session: true
    }
  });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      processing: false
    }
  });

  NavigationService.navigate({
    name: 'Flow',
    flowId
  });

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:success.donorLogin.title'),
    body: I18n.t('growl:success.donorLogin.body', { ba }),
    client,
    kind: 'info'
  });
};

export const donorLoginError = function* () {
  const client = yield select(clientSelector);
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      error: true,
      processing: false,
      badge_number: ''
    }
  });

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.donorLogin.title'),
    body: I18n.t('growl:error.donorLogin.body'),
    client,
    kind: 'error'
  });

  yield delay(1000);

  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'error',
    value: false
  });
};

export const donorLoginSuccessful = function* ({ payload: user }) {
  // set user and proceed to get flow
  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      token: AES.encrypt(user.auth_token, Config.PASSPHRASE).toString()
    }
  });

  yield put({
    type: UserTypes.SET_USER,
    payload: user
  });

  yield put({
    type: FlowTypes.GET_DONOR_FLOW,
    id: user.published_flow_id
  });
};

export const initFrameworkSeven = () => {
  NavigationService.init();
  NavigationService.listenEvent({ view: 'main', eventName: 'routeChanged' });
  if ('serviceWorker' in navigator) {
    ServiceWorker.listen(onServiceWorkerMessage);
  }
  window.addEventListener('offline', onOffline);
  window.addEventListener('online', onOnline);
  window.addEventListener('orientationchange', orientationChangeDetected);
  orientationChangeDetected();
  PwaService.init();
};

export const login = function* ({ email, password }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      enableAgentLogin: false,
      processing: true
    }
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.BACKEND_LOGIN_SUCCESSFUL },
      fail: { type: ApplicationTypes.BACKEND_LOGIN_ERROR }
    },
    promise: User.login(email, password)
  });
};

export const logout = function* () {
  const firstName = yield select(firstNameSelector);

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:success.logout.title', {
      first_name: firstName
    }),
    body: I18n.t('growl:success.logout.body'),
    kind: 'info'
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.CLEAR_SESSION },
      fail: { type: ApplicationTypes.CLEAR_SESSION }
    },
    promise: Api.delete('https://staging.databyte.live/v2/sessions/')
  });
};

export const navigateTo = navigateToExport;

export const onOrientationChange = onOrientationChangeExported;

export const persisted = function* () {
  const currentLocalTime = Date.now();
  const donorFlowId = yield select(donorFlowIdSelector);
  const isOffline = yield select(isOfflineSelector);
  const mode = yield select(modeSelector);
  const offlineSince = yield select(offlineSinceSelector);
  let session = yield select(sessionSelect);
  const currentORID = yield select(orIDSelector);
  let token = yield select(tokenSelect);

  const { orID, client, ba, country } = checkSearchParams(window.location.search);
  let { isDonor } = checkSearchParams(window.location.search);

  if (window.location.origin.includes('donor')) {
    isDonor = true;
  }

  if (country) {
    yield put({
      type: ApplicationTypes.UPDATE_PROPS,
      props: {
        country: country.toLowerCase()
      }
    });
  }

  if (isOffline && offlineSince && Math.abs(currentLocalTime - offlineSince) > Config.LOGOUT_THRESHOLD) {
    yield put({ type: ApplicationTypes.SECURITY_LOGOUT });
  } else {
    if (isDonor && !(orID && client && ba)) {
      yield put({
        type: ApplicationTypes.BROKEN_OR_UNAUTHORIZED_ACCESS,
        action: yield put({
          type: ApplicationTypes.CLOSE_SPLASH_SCREEN
        })
      });
    } else {
      if (isDonor && currentORID !== orID) {
        yield call(clearSession);
        session = null;
        token = null;
      }
      NavigationService.navigate({
        action: yield put({
          type: ApplicationTypes.CLOSE_SPLASH_SCREEN
        }),
        name: session
          ? mode === 'donor'
            ? defaultRoutes.donorSession.name
            : defaultRoutes.session.name
          : defaultRoutes.noSession.name,

        ba,
        client,
        flowId: donorFlowId,
        isDonor,
        orID
      });
    }
    if (session && token) {
      Api.auth_token(AES.decrypt(token, Config.PASSPHRASE).toString(Utf8));
    }
  }
};

export const securityLogout = function* () {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:success.securityLogout.title'),
    body: I18n.t('growl:success.securityLogout.body'),
    kind: 'error',
    persist: true
  });
  yield put({
    type: ApplicationTypes.CLOSE_SPLASH_SCREEN
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ApplicationTypes.CLEAR_SESSION },
      fail: { type: ApplicationTypes.CLEAR_SESSION }
    },
    promise: Api.delete('https://staging.databyte.live/v2/sessions/')
  });
};

export const sessionExpiredLogout = function* () {
  yield delay(parseInt(Config.GROWL_AUTOHIDE) / 10); // required otherwise F7 router will not navigate
  yield put({
    type: GrowlTypes.ALERT,
    kind: 'error',
    title: I18n.t('growl:error.backend.session.title'),
    body: I18n.t('growl:error.backend.session.body')
  });
  yield put({
    type: ApplicationTypes.CLOSE_SPLASH_SCREEN
  });
  yield put({
    type: ApplicationTypes.CLEAR_SESSION
  });
};

export const userIsOnline = function* () {
  yield put({
    type: ApplicationTypes.UPDATE_PROPS,
    props: {
      isOffline: false,
      offlineSince: null
    }
  });

  yield put({
    type: FlowTypes.CHECK_OFFLINE_FLOWS
  });
};
