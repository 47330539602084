import { call, put, select } from 'redux-saga/effects';

import Config from 'Config';
import { I18n } from 'Locales';
import { Types as FlowTypes } from 'Reducers/flow';
import { Types as GrowlTypes } from 'Reducers/growl';
import { Types as ResultTypes } from 'Reducers/result';
import { Types as CampaignTypes } from 'Reducers/campaign';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { Types as TransientTypes } from 'Reducers/transient';
import { Types as ApplicationTypes } from 'Reducers/application';
import {
  lastKnownAvatar as lastKnownAvatarSelect,
  lastKnownEmail as lastKnownEmailSelect,
  offlineSince as offlineSinceSelector,
  session as sessionSelector,
  isOffline as isOfflineSelector,
  warnedSecurityLogout as warnedSecurityLogoutSelector
} from 'Reducers/application';

const warnSecurityLogout = function* () {
  const currentLocalTime = Date.now();
  const offlineSince = yield select(offlineSinceSelector);

  if (Math.abs(currentLocalTime - offlineSince) > Config.WARN_THRESHOLD) {
    yield put({
      type: ApplicationTypes.UPDATE_PROP,
      key: 'warnedSecurityLogout',
      value: true
    });

    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:warn.securityLogout.title'),
      body: I18n.t('growl:warn.securityLogout.body'),
      kind: 'error',
      persist: true
    });
  }
  if (Math.abs(currentLocalTime - offlineSince) > Config.LOGOUT_THRESHOLD) {
    yield put({ type: ApplicationTypes.SECURITY_LOGOUT });
  }
};

export const navigateTo = function* ({ params }) {
  const lastKnownAvatar = yield select(lastKnownAvatarSelect);
  const lastKnownEmail = yield select(lastKnownEmailSelect);
  const session = yield select(sessionSelector);
  const isOffline = yield select(isOfflineSelector);
  const offlineSince = yield select(offlineSinceSelector);
  const warnedSecurityLogout = yield select(warnedSecurityLogoutSelector);

  if (session && !warnedSecurityLogout && isOffline && offlineSince) {
    yield call(warnSecurityLogout);
  }

  const { ba, client, flowId, isDonor, lastTransId, name, orID } = params;
  switch (name) {
    case 'Login':
      if (isDonor && orID && client && ba) {
        yield put({
          type: TransientTypes.UPDATE_PROPS,
          props: {
            ba,
            badge_number: '',
            client,
            enableDonorLogin: false,
            isDonor,
            orID
          }
        });
        yield put({
          type: ApplicationTypes.UPDATE_PROPS,
          props: {
            mode: 'donor',
            ba,
            client,
            orID
          }
        });
      } else {
        yield put({
          type: TransientTypes.UPDATE_PROPS,
          props: {
            avatar: lastKnownAvatar,
            email: lastKnownEmail,
            enableAgentLogin: false,
            password: ''
          }
        });
      }

      break;
    case 'Campaigns':
      yield put({ type: CampaignTypes.GET });
      yield put({ type: CheckInTypes.GET });
      break;
    case 'Flow':
      yield put({ type: FlowTypes.INIT_FLOW_METADATA, id: flowId });
      break;
    case 'Results':
      yield put({ type: ResultTypes.GET });
      break;
    case 'ThankYou':
      yield put({
        type: ApplicationTypes.UPDATE_PROP,
        key: 'lastTransactionId',
        value: lastTransId
      });
  }
};
