import I18n from 'i18next';

import de from './de';
import en from './en';
import fr from './fr';

const detectBrowserLanguage = () => {
  const fullLanguageCode =
    (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
  return fullLanguageCode.split('-')[0];
};

I18n.init({
  resources: {
    de,
    en,
    fr
  },
  lng: detectBrowserLanguage(),

  fallbackLng: 'en',
  whitelist: ['de', 'en', 'fr']
});

export default I18n;
