import { connect } from 'react-redux';

import { Creators as applicationActions } from 'Reducers/application';

import ForgotPassword from './view';

const emptyStateToProps = () => ({});

export default connect(emptyStateToProps, dispatch => ({
  dispatch,
  login: applicationActions.login
}))(ForgotPassword);
