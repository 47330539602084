import Config from 'Config';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const DynamicLogo = props => {
  const { height, name } = props;
  const [hasError, setHasError] = useState(false);

  return hasError ? (
    name?.toUpperCase()
  ) : (
    <img
      onError={setHasError.bind(null, true)}
      src={`${Config.CLIENT_LOGO_ENDPOINT}${name}${Config.CLIENT_LOGO_EXTENSION}`}
      height={height}
    />
  );
};

DynamicLogo.propTypes = {
  name: PropTypes.string,
  height: PropTypes.number
};

DynamicLogo.defaultProps = {
  height: 40,
  name: ''
};

export default DynamicLogo;
