import Framework7 from 'framework7/framework7-lite.esm';
import Sheet from 'framework7/components/sheet/sheet';
import Panel from 'framework7/components/panel/panel';
import LoginScreen from 'framework7/components/login-screen/login-screen';
import Grid from 'framework7/components/grid/grid';
import Input from 'framework7/components/input/input';
import Preloader from 'framework7/components/preloader/preloader';
import Calendar from 'framework7/components/calendar/calendar';
import SmartSelect from 'framework7/components/smart-select/smart-select';
import VirtualList from 'framework7/components/virtual-list/virtual-list';
import Searchbar from 'framework7/components/searchbar/searchbar';
import Autocomplete from 'framework7/components/autocomplete/autocomplete';
import Fab from 'framework7/components/fab/fab';
import Card from 'framework7/components/card/card';
import Checkbox from 'framework7/components/checkbox/checkbox';
import Radio from 'framework7/components/radio/radio';
import Tabs from 'framework7/components/tabs/tabs';
import Dialog from 'framework7/components/dialog/dialog';

import 'framework7/framework7.less';
import 'framework7/components/sheet.css';
import 'framework7/components/panel.css';
import 'framework7/components/login-screen.css';
import 'framework7/components/input.css';
import 'framework7/components/grid.css';
import 'framework7/components/preloader.css';
import 'framework7/components/calendar.css';
import 'framework7/components/smart-select.css';
import 'framework7/components/virtual-list.css';
import 'framework7/components/searchbar.css';
import 'framework7/components/autocomplete.css';
import 'framework7/components/fab.css';
import 'framework7/components/card.css';
import 'framework7/components/checkbox.css';
import 'framework7/components/radio.css';
import 'framework7/components/tabs.css';
import 'framework7/components/dialog.css';

Framework7.use([
  Sheet,
  Panel,
  LoginScreen,
  Grid,
  Input,
  Preloader,
  Calendar,
  SmartSelect,
  VirtualList,
  Searchbar,
  Autocomplete,
  Fab,
  Card,
  Checkbox,
  Radio,
  Tabs,
  Dialog
]);

export default Framework7;
