import React from 'react';
import PropTypes from 'prop-types';
import { Button as F7Button, Preloader } from 'framework7-react';

import { f7Colors } from 'Theme';

const Primary = props => <Button {...props} fill round largeMd />;
const Transparent = props => <Button {...props} />;

const Button = props => {
  const { children, disabled, onClick, processing } = props;

  const componsedStyle = {
    width: props.width
  };

  return (
    <F7Button {...props} disabled={disabled} onClick={onClick} style={componsedStyle}>
      {processing && <Preloader size={20} color={f7Colors.white} />}
      {!processing && children}
    </F7Button>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  processing: PropTypes.bool,
  width: PropTypes.any
};

Button.defaultProps = {
  children: '',
  color: f7Colors.blue,
  disabled: false,
  onClick: noAction,
  processing: false,
  width: 'auto'
};

const noAction = () => {};

export default { Primary, Transparent };
