import { call, delay, put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import Config from 'Config';
import { I18n } from 'Locales';
import * as Selectors from 'Selectors';
import { NavigationService } from 'Services';
import { Types as GrowlTypes } from 'Reducers/growl';
import { user as userSelector } from 'Reducers/user';
import { generateComponentProperties } from 'Helpers';
import { Types as ResultTypes } from 'Reducers/result';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { CheckIn, Flow, Result, User } from 'Repositories';
import { Types as PostCodesTypes } from 'Reducers/postCodes';
import { Types as ApplicationTypes, mode as modeSelector } from 'Reducers/application';
import {
  Types as FlowTypes,
  availablePages as availablePagesSelector,
  currentFlow as currentFlowSelector,
  currentFlowData as currentFlowDataSelector,
  currentFlowComponentLogicRules as currentFlowComponentLogicRulesSelector,
  currentFlowPageLogicRules as currentFlowPageLogicRulesSelector,
  currentFlowValidations as currentFlowValidationsSelector,
  currentPageIndex as currentPageIndexSelector,
  flows as flowsSelector,
  flowPages as flowPagesSelector,
  flowSignature as flowSignatureSelector,
  timeStartedFlow as timeStartedFlowSelector,
  validatedPages as validatedPagesSelector
} from 'Reducers/flow';

const checkComponents = function* ({ id, components, currentFlowData }) {
  for (const { logic_rules, properties, component_key: targetKey } of components) {
    const hasLogicRules = logic_rules.length > 0;
    const required = generateComponentProperties(properties).required;
    let visible = true;

    if (hasLogicRules) {
      // component can have defined only 1 logic rule
      const {
        action,
        value,
        predicate,
        component: { component_key: source_key, fields }
      } = logic_rules[0];
      yield put({
        type: FlowTypes.REGISTER_LOGIC_RULE,
        logicType: 'component',
        id: source_key,
        action,
        predicate,
        value,
        componentId: targetKey,
        fieldId: fields[0].id,
        fieldName: fields[0].name,
        componentExtras: {
          required,
          pageId: id
        }
      });
      visible = predicateCondition(predicate, '', value) && action === 'show';
    }

    if (required && visible && !(currentFlowData && currentFlowData[targetKey]?.value)) {
      yield put({
        type: FlowTypes.INVALIDATE_COMPONENT,
        pageId: id,
        componentKey: targetKey
      });

      yield put({
        type: FlowTypes.UPDATE_CURRENT_FLOW_DATA,
        key: targetKey,
        value: {
          isValid: false
        }
      });
    }
  }
};

export const checkOfflineFlows = function* () {
  const offlineFlows = yield select(Selectors.offlineFlows);
  if (offlineFlows.length > 0) {
    for (const offlineFlow of offlineFlows) {
      yield call(tryExecutionToken.bind(null, offlineFlow));
    }
  }
};

const generatePageLogicRules = function* ({ id: pageId, logic_rules }) {
  for (const { action, predicate, value, related, component } of logic_rules) {
    if (related && component) {
      const { id: fieldId } = related;
      const { id: componentId } = component;
      yield put({
        type: FlowTypes.REGISTER_LOGIC_RULE,
        logicType: 'page',
        id: pageId,
        action,
        predicate,
        value,
        componentId,
        fieldId
      });
    }
  }
};

const navigateFlow = function* ({ step }) {
  const currentPageIndex = yield select(currentPageIndexSelector);
  const currentFlow = yield select(currentFlowSelector);

  yield put({
    type: FlowTypes.UPDATE_PROPS,
    props: {
      currentPage: currentFlow.flow_pages[currentPageIndex + step],
      currentPageIndex: currentPageIndex + step,
      pageIsInvalidOnNext: false
    }
  });
  document.querySelector('.page-current > .page-content').scrollTo({ top: 0, behavior: 'smooth' });
};

const predicateCondition = (predicate, updatingValue, value) => {
  if (Array.isArray(updatingValue)) {
    updatingValue = updatingValue.join(' '); // checkboxes' value is array
  }

  switch (predicate) {
    case 'equal_to':
      return value === updatingValue;

    case 'not_equal_to':
      return value !== updatingValue;

    case 'contains':
      if (typeof updatingValue === 'number') {
        updatingValue = updatingValue.toString();
      }
      return updatingValue?.includes(value);

    case 'does_not_contain':
      if (typeof updatingValue === 'number') {
        updatingValue = updatingValue.toString();
      }
      return !updatingValue?.includes(value);

    case 'greater_than':
      return parseFloat(updatingValue) > parseFloat(value);

    case 'less_than':
      return parseFloat(updatingValue) < parseFloat(value);

    case 'greater_than_or_equal_to':
      return parseFloat(updatingValue) >= parseFloat(value);

    case 'less_than_or_equal_to':
      return parseFloat(updatingValue) <= parseFloat(value);
  }
};

const pageIdFilter = (page, pageId) => pageId !== page;

// EXPORTED
export const forceUpdatePageComponents = function* () {
  const currentFlow = yield select(currentFlowSelector);
  const currentFlowData = yield select(currentFlowDataSelector);
  const currentPageIndex = yield select(currentPageIndexSelector);
  if (currentFlow) {
    const currentPage = currentFlow.flow_pages[currentPageIndex];

    if (currentPage) {
      for (const { component_key } of currentPage.components) {
        if (currentFlowData[component_key]) {
          yield put({
            type: FlowTypes.UPDATE_CURRENT_FLOW_DATA,
            key: component_key,
            value: {
              ...currentFlowData[component_key],
              forceUpdate: currentFlowData[component_key].forceUpdate
                ? currentFlowData[component_key].forceUpdate + 1
                : 0
            }
          });
        }
      }
    }
  }
};

export const get = function* ({ id, checkInId }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SET },
      fail: { type: FlowTypes.GET_ERROR, checkInId }
    },
    promise: Flow.get(id)
  });
};

export const getDonorFlow = function* ({ id }) {
  // step 2 authentication for donor mode
  const applicationMode = yield select(modeSelector);
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SET_DONOR_FLOW, applicationMode },
      fail: { type: ApplicationTypes.DONOR_LOGIN_ERROR }
    },
    promise: Flow.get(id)
  });
};

export const getError = function* ({ checkInId, error: { status } }) {
  if (status === 404) {
    yield put({
      type: CheckInTypes.CHECK_OUT,
      id: checkInId
    });
  } else {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.flow.get.title'),
      body: I18n.t('growl:error.flow.get.body'),
      kind: 'error'
    });
  }
};

export const getExecutionToken = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_FLOW },
      fail: { type: FlowTypes.SUBMIT_FLOW }
    },
    promise: User.getExecutionToken()
  });
};

export const initFlowMetadata = function* ({ id }) {
  const mode = yield select(modeSelector);
  const flows = yield select(flowsSelector);
  const currentFlowPageLogicRules = yield select(currentFlowPageLogicRulesSelector);
  const flowPageIds = [];
  const availablePages = [];
  const { flow_pages, result_data } = flows[id];

  for (const { id, components, logic_rules, page_type } of flow_pages) {
    if (page_type === 'regular') {
      flowPageIds.push(id);
      if (logic_rules.length > 0) {
        yield call(generatePageLogicRules, { id, logic_rules });
      }
      yield call(checkComponents, { id, components, currentFlowData: result_data });
      if (!currentFlowPageLogicRules[id]) {
        availablePages.push(id);
      }
    }
  }

  if (result_data) {
    // donor login, got data from BA
    const keys = Object.keys(result_data);

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // can be deprecated after old agent is decomissioned
      const resultValue = result_data[key].value;
      if (!resultValue || (typeof resultValue === 'object' && keys && keys.length === 0)) {
        delete result_data[key];
      } else {
        if (result_data[key].component === 'confirmed_email' && typeof result_data[key].value === 'string') {
          result_data[key].value = {
            email: result_data[key].value,
            emailConfirmation: result_data[key].value
          };
        }

        yield put({
          type: FlowTypes.UPDATE_CURRENT_FLOW_DATA,
          key: key,
          value: { ...result_data[key] }
        });
      }
    }
  }

  yield put({
    type: FlowTypes.UPDATE_PROPS,
    props: {
      currentFlowIndex: id,
      currentPage: flow_pages[0],
      currentPageIndex: 0,
      flowPages: flowPageIds,
      availablePages: availablePages
    }
  });

  yield put({
    type: FlowTypes.RUN_PAGE_VALIDATIONS,
    action: mode === 'donor' ? { type: FlowTypes.NAVIGATE_TO_FIRST_INVALIDATED_PAGE } : null
  });
};

export const initSubmitFlow = function* () {
  yield call(getExecutionToken);
};

export const navigateToFirstInvalidatedPage = function* () {
  const availablePages = yield select(availablePagesSelector);
  const validatedPages = yield select(validatedPagesSelector);
  const flowPages = yield select(flowPagesSelector);
  const invalidatedPages = availablePages.filter(p => !validatedPages.includes(p));
  if (invalidatedPages.length > 0) {
    const nextInvalidPage = invalidatedPages[0];
    yield call(navigateFlow, { step: flowPages.indexOf(nextInvalidPage) });
  } else {
    yield call(navigateFlow, { step: flowPages.length });
  }
};

export const nextFlowPage = function* () {
  const currentPageIndex = yield select(currentPageIndexSelector);
  const availablePages = yield select(availablePagesSelector);
  const flowPages = yield select(flowPagesSelector);
  const validatedPages = yield select(validatedPagesSelector);
  let step = 1;

  while (!availablePages.includes(flowPages[currentPageIndex + step]) && currentPageIndex + step < flowPages.length) {
    step = step + 1;
  }

  if (validatedPages.includes(flowPages[currentPageIndex])) {
    yield call(navigateFlow, { step });
  } else {
    yield call(forceUpdatePageComponents);
    yield put({
      type: FlowTypes.UPDATE_PROP,
      key: 'pageIsInvalidOnNext',
      value: true
    });
  }
};

export const previousFlowPage = function* () {
  const currentPageIndex = yield select(currentPageIndexSelector);
  const availablePages = yield select(availablePagesSelector);
  const flowPages = yield select(flowPagesSelector);
  let step = -1;
  while (!availablePages.includes(flowPages[currentPageIndex + step]) && currentPageIndex - step > 0) {
    step = step - 1;
  }
  yield call(navigateFlow, { step });
};

export const runComponentLogicRules = function* ({ key, value: updatingValue = null }) {
  const { result_data } = yield select(currentFlowSelector);
  const currentFlowData = yield select(currentFlowDataSelector);

  const currentFlowComponentLogicRules = yield select(currentFlowComponentLogicRulesSelector);
  const mode = yield select(modeSelector);
  if (key && updatingValue) {
    yield delay(250);
    const foundLogicRules = currentFlowComponentLogicRules[key];
    if (foundLogicRules && foundLogicRules.length > 0) {
      for (const foundLogicRule of foundLogicRules) {
        const {
          action,
          componentId,
          predicate,
          value,
          componentExtras: { pageId, required }
        } = foundLogicRule;
        const uValue = updatingValue.isProduct ? updatingValue?.productValue : updatingValue?.value;
        if (
          (updatingValue?.isValid || updatingValue?.isValid === undefined) &&
          predicateCondition(predicate, uValue, value) &&
          action === 'show'
        ) {
          if (!currentFlowData[componentId]) {
            yield put({
              type: FlowTypes.SHOW_COMPONENT,
              id: componentId
            });
            if ((required && mode === 'agent') || (required && mode === 'donor' && !result_data[componentId]?.value)) {
              yield put({
                type: FlowTypes.INVALIDATE_COMPONENT,
                pageId,
                componentKey: componentId
              });
            }
          }
        } else {
          if (currentFlowData[componentId]) {
            yield put({
              type: FlowTypes.STRIP_COMPONENT,
              id: componentId
            });
            yield put({
              type: FlowTypes.VALIDATE_COMPONENT,
              pageId,
              componentKey: componentId
            });
          }
        }
      }
    }

    yield put({
      type: FlowTypes.RUN_PAGE_LOGIC_RULES,
      value: updatingValue
    });
  }
};

export const runPageLogicRules = function* ({ value: updatingValue = null }) {
  if (updatingValue) {
    yield delay(250);
    const flowPages = yield select(flowPagesSelector);
    const currentFlowPageLogicRules = yield select(currentFlowPageLogicRulesSelector);
    const originalAvailablePages = yield select(availablePagesSelector);
    let availablePages = [...originalAvailablePages];

    for (const page of flowPages) {
      if (currentFlowPageLogicRules[page]) {
        const { componentId, action, predicate, value } = currentFlowPageLogicRules[page];
        if (updatingValue?.id === componentId) {
          if (
            (updatingValue?.isValid || updatingValue?.isValid === undefined) &&
            predicateCondition(predicate, updatingValue?.value, value) &&
            action === 'show'
          ) {
            if (!availablePages.includes(page)) {
              availablePages.push(page);
            }
          } else {
            if (availablePages.includes(page)) {
              availablePages = availablePages.filter(pageIdFilter.bind(null, page));
            }
          }
        }
      }
    }

    if (originalAvailablePages.length !== availablePages.length) {
      const removedPages = originalAvailablePages.filter(page => !availablePages.includes(page));
      yield put({
        type: FlowTypes.STRIP_PAGES,
        pages: removedPages
      });
    }

    yield put({
      type: FlowTypes.UPDATE_PROP,
      key: 'availablePages',
      value: availablePages
    });

    yield put({
      type: FlowTypes.RUN_PAGE_VALIDATIONS
    });
  }
};

export const runPageValidations = function* ({ action }) {
  yield delay(250);
  const availablePages = yield select(availablePagesSelector);
  const currentFlowValidations = yield select(currentFlowValidationsSelector);
  for (const pageId of availablePages) {
    if (currentFlowValidations[pageId]?.length > 0) {
      yield put({
        type: FlowTypes.INVALIDATE_PAGE,
        pageId
      });
    } else {
      yield put({
        type: FlowTypes.VALIDATE_PAGE,
        pageId
      });
    }
  }
  if (action) {
    yield put(action);
  }
};

export const setDonorFlow = function* ({ payload: { country } }) {
  const user = yield select(userSelector);
  if (!['BE', 'DE', 'IE', 'UK'].includes(country)) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: PostCodesTypes.SET_DONOR_ADDRESSES },
        fail: { type: ApplicationTypes.DONOR_LOGIN_ERROR }
      },
      promise: CheckIn.getAddress(user.selected_postcodes, country.toLowerCase())
    });
  }
};

export const saveFlowForOffline = function* ({ data }) {
  const user = yield select(userSelector);
  const timeStartedFlow = yield select(timeStartedFlowSelector);
  const offlineResult = {
    type: 'results',
    id: data.session_id,
    current_time: new Date().toJSON(),
    offline_id: data.session_id,
    created_at: timeStartedFlow,
    submitted_at: data.submitted_at,
    state: 'offline',
    campaign: data.campaign_name,
    offlineData: data
  };

  yield put({
    type: ResultTypes.ADD_OFFLINE_RESULT,
    userId: user.id,
    offlineResult
  });

  NavigationService.navigate({
    name: 'ThankYou',
    lastTransId: offlineResult.id
  });
};

export const submitFlow = function* ({ payload }) {
  const currentFlowData = yield select(currentFlowDataSelector);
  const flowId = yield select(Selectors.flowId);
  const currentCheckIn = yield select(Selectors.checkInObject);
  const selectedCampaignName = yield select(Selectors.selectedCampaignName);
  const user = yield select(userSelector);
  const flowSignature = yield select(flowSignatureSelector);
  const timeStartedFlow = yield select(timeStartedFlowSelector);

  const result_signature = {
    component: 'flowSignature',
    value: flowSignature?.base64,
    visible: true
  };

  const data = {
    published_flow_id: flowId,
    campaign_name: selectedCampaignName,
    user_id: user?.id,
    check_in_id: currentCheckIn?.id,
    data: { ...currentFlowData, result_signature },
    agent_version: Config.APP_VERSION,
    execution_token: payload?.execution_token,
    submitted_at: Date.now(),
    session_id: user?.id + '-' + timeStartedFlow,
    lat: null,
    long: null
  };

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_FLOW_SUCCESS },
      fail: { type: FlowTypes.SUBMIT_FLOW_ERROR, data }
    },
    promise: Result.submitFlow(data)
  });
};

export const submitFlowSuccess = function ({ payload }) {
  NavigationService.navigate({
    name: 'ThankYou',
    lastTransId: payload[0].id
  });
};

export const submitFlowError = function* ({ data }) {
  const mode = yield select(modeSelector);

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.flow.submit.title'),
    body: I18n.t('growl:error.flow.submit.body'),
    kind: 'error',
    persist: true,
    buttons: [
      { action: 'initSubmitFlow', label: I18n.t('growl:error.flow.submit.buttons.retry') },
      mode === 'agent' && { action: 'saveFlowForOffline', label: I18n.t('growl:error.flow.submit.buttons.save') }
    ],
    rest: data
  });
};

export const submitOfflineFlow = function* ({ payload, offlineFlow }) {
  const { offlineData } = offlineFlow;
  offlineData.execution_token = payload.execution_token;

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_OFFLINE_FLOW_SUCCESS, offlineFlow },
      fail: {}
    },
    promise: Result.submitFlow(offlineData)
  });
};

export const submitOfflineFlowSuccess = function* ({ offlineFlow }) {
  const user = yield select(userSelector);

  yield put({
    type: ResultTypes.SPLICE_OFFLINE_FLOW_BY_ID,
    userId: user.id,
    offlineFlow
  });
};

export const tryExecutionToken = function* (offlineFlow) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: FlowTypes.SUBMIT_OFFLINE_FLOW, offlineFlow }
    },
    promise: User.getExecutionToken()
  });
};

export const validateOnlineBankAccount = function* ({ pageId, component, updateValues }) {
  const {
    value: { accountNumber, sortCode, registrationNumber }
  } = updateValues;
  const { country } = component;

  let promise;

  if (country === 'UK' && accountNumber?.length > 0 && sortCode?.length > 0) {
    promise = Flow.bankAccountCheckUK.bind(null, accountNumber, sortCode);
  } else if (['BE', 'DE', 'FR', 'IE'].includes(country) && registrationNumber?.length > 0) {
    const formattedRegistrationNumber = registrationNumber.replace(/\s/g, '');
    promise = Flow.bankAccountCheckFR.bind(null, formattedRegistrationNumber);
  }

  if (typeof promise === 'function') {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT_SUCCESS, pageId, component, updateValues },
        fail: { type: FlowTypes.VALIDATE_ONLINE_BANK_ACCOUNT_ERROR, pageId, component, updateValues }
      },
      promise: promise()
    });
  } else {
    //cannot validate online, but component is offline valid
    yield put({
      type: FlowTypes.VALIDATE_COMPONENT,
      pageId,
      componentKey: component.component_key
    });
  }
};

export const validateOnlineBankAccountSuccess = function* ({ component, pageId, payload }) {
  let type;
  let isValid;
  let partialValue = {};

  if (component.country !== 'UK' && payload.error_list.length > 0) {
    isValid = false;
    partialValue = {
      validationMessage: (payload?.error_messages && payload?.error_messages[0]) || '',
      validationStatus: 'INVALID'
    };
    type = FlowTypes.INVALIDATE_COMPONENT;
  } else if (payload.error) {
    isValid = false;
    partialValue = {
      validationMessage: payload.error,
      validationStatus: 'INVALID'
    };
    type = FlowTypes.INVALIDATE_COMPONENT;
  } else {
    isValid = true;
    partialValue = {
      validationStatus: 'VALID',
      validationMessage: '',
      bankName: payload.bank
    };

    if (['BE', 'DE', 'FR', 'IE'].includes(component.country)) {
      partialValue = { ...partialValue, bic: payload.bic, bankName: payload.bank };
    }
    type = FlowTypes.VALIDATE_COMPONENT;
  }

  yield put({
    type: FlowTypes.UPDATE_BANK_COMPONENT_FLOW_DATA,
    key: component.component_key,
    value: partialValue,
    isValid: isValid
  });

  yield put({
    type,
    pageId,
    componentKey: component.component_key
  });
};

export const validateOnlineBankAccountError = function* ({ component, updateValues }) {
  const componentValue = {
    ...updateValues,
    value: { ...updateValues.value, validationStatus: 'ERROR', validationMessage: 'Server Error' }
  };

  yield put({
    type: FlowTypes.UPDATE_CURRENT_FLOW_DATA,
    key: component.component_key,
    value: componentValue
  });
};
