import React from 'react';
import PropTypes from 'prop-types';
import { Page, Navbar, Block, BlockTitle, List, ListItem, Preloader } from 'framework7-react';

import { I18n } from 'Locales';
import { f7Colors } from 'Theme';
import { ColumnView } from 'Containers';

import { CheckInList } from './subviews';

const CheckIn = ({ checkIn, postCodes, setSelectedPostcodes }) => (
  <Page>
    <Navbar title={I18n.t('checkIn:title')} backLink onBackClick={checkIn}></Navbar>

    <Block>
      {postCodes.length > 0 ? (
        <CheckInList postCodes={postCodes} setSelectedPostcodes={setSelectedPostcodes} />
      ) : (
        <List>
          <ListItem title={I18n.t('checkIn:postcode')}>
            <Preloader size={20} color={f7Colors.multi} />
          </ListItem>
        </List>
      )}

      <ColumnView justifyContent="flex-start">
        <img src={'/static/images/hand-tablet-map.png'} />

        <BlockTitle large>{I18n.t('checkIn:descriptionTitle')}</BlockTitle>
        <p>{I18n.t('checkIn:descriptionMessage')}</p>
      </ColumnView>
    </Block>
  </Page>
);

CheckIn.propTypes = {
  checkIn: PropTypes.func,
  postCodes: PropTypes.array,
  setSelectedPostcodes: PropTypes.func
};

export default CheckIn;
