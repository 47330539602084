import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Page } from 'framework7-react';

import { Fab } from 'Components';
import { Message } from 'FlowComponentTypes';
import { NavigationService } from 'Services';

const ThankYou = ({ fields, logout, mode }) => (
  <Page>
    <Message fields={fields} />
    <Fab
      disabled={false}
      onClick={mode === 'agent' ? NavigationService.navigate.bind(null, {
        name: 'Campaigns'
      }) : logout}
      position={'right-bottom'}
      slot={'fixed'}>
      <Icon md={'material:exit_to_app'}></Icon>
    </Fab>
  </Page>
);

ThankYou.propTypes = {
  fields: PropTypes.array,
  logout: PropTypes.func,
  mode: PropTypes.string
};

export default ThankYou;
