import { delay, put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { CheckIn } from 'Repositories';
import { Types as FlowTypes } from 'Reducers/flow';
import { flowId as flowIdSelector } from 'Selectors';
import { user as userSelector } from 'Reducers/user';
import { Types as CheckInTypes } from 'Reducers/checkIn';
import { Types as TransientTypes } from 'Reducers/transient';
import { Types as PostCodesTypes, selectedPostcodes as selectedPostcodesSelector } from 'Reducers/postCodes';

import { NavigationService } from 'Services';

export const checkIn = function* () {
  const selectedPostcodes = yield select(selectedPostcodesSelector);
  const flowId = yield select(flowIdSelector);

  NavigationService.goBack();

  if (selectedPostcodes.length > 0) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: CheckInTypes.GET },
        fail: { type: Api.API_ERROR }
      },
      promise: CheckIn.checkIn(flowId, selectedPostcodes.join(', '))
    });
    yield put({
      type: PostCodesTypes.UPDATE_PROP,
      key: 'selectedPostcodes',
      value: []
    });
  }
};

export const checkOut = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CheckInTypes.GET },
      fail: { type: Api.API_ERROR }
    },
    promise: CheckIn.checkOut(id)
  });
};

export const get = function* () {
  const user = yield select(userSelector);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: CheckInTypes.SET, userId: user.id },
      fail: { type: Api.API_ERROR }
    },
    promise: CheckIn.get()
  });
};

export const set = function* ({ payload: checkIns, userId }) {
  for (const checkIn of checkIns) {
    yield put({
      type: FlowTypes.GET,
      id: checkIn.published_flow.id,
      checkInId: checkIn.id
    });

    yield put({
      type: Api.API_CALL,
      actions: {
        success: {
          type: CheckInTypes.SET_ADDRESS,
          userId,
          checkInId: checkIn.id
        },
        fail: { type: Api.API_ERROR }
      },
      promise: CheckIn.getAddress(checkIn.selected_postcodes, checkIn.country.toLowerCase())
    });
  }
};

export const getUKAddress = function* ({ data }) {
  yield delay(300);
  yield put({
    type: Api.API_CALL,
    actions: {
      success: {
        type: CheckInTypes.GET_UK_ADDRESS_SUCCESS
      },
      fail: { type: CheckInTypes.GET_UK_ADDRESS_FAIL }
    },
    promise: CheckIn.getUKAddress(data)
  });
};

export const getUKAddressSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'uk_address',
    value: payload
  });
};

export const getUKAddressFail = function* () {
  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'disabled',
    value: false
  });
};
