import Config from 'Config';
import { isObjEmpty, regularPagesOnly, thankYouPageOnly } from 'Helpers';

const checkInFilter = (state, ci) => ci.published_flow.id === flowId(state);

const checkInObject = state => {
  try {
    return state.checkIn[state.user.id]?.checkIns.filter(checkInFilter.bind(null, state))[0];
  } catch (e) {
    return null;
  }
};

const filterBadges = item => item.campaign.id === Config.ALLOW_CAMPAIGN_ID;

const flow = state => state.flow.flows[state.flow.currentFlowIndex];

const flowId = state => {
  try {
    return state.campaign[state.user.id]?.selectedCampaign?.published_flows?.[0].id || undefined;
  } catch (e) {
    return undefined;
  }
};

const flowReady = state => (state.flow.flows[flowId(state)] ? true : false);

const flowPages = state => flow(state)?.flow_pages.filter(regularPagesOnly) || [];

const getCurrentRouteName = state => state.application.routing.history[state.application.routing.history.length - 1];

const hasValidBadge = state => !isObjEmpty(validBadgeNumber(state));

const isCheckedIn = state => !!checkInObject(state);

const isSubmittedOffline = state => lastResultId(state)?.startsWith(state.user.id);

const lastResultId = state => state.application.lastTransactionId;

const offlineFlows = state => state.result[state.user.id]?.offline || [];

const recentResults = state => state.result[state.user.id]?.results || [];

const selectedCampaignFilter = (state, item) => item.id == state.user.campaign?.id;

const selectedCampaignName = state => {
  const uc = userCampaigns(state);
  return uc.length > 0 ? uc.filter(selectedCampaignFilter.bind(this, state))[0]?.name : '';
};

const thankYouPageFields = state => flow(state)?.flow_pages?.filter(thankYouPageOnly)[0]?.components[0]?.fields || [];

const userCampaigns = state => {
  try {
    return state.campaign[state.user.id]?.campaigns || [];
  } catch (e) {
    return [];
  }
};

const validBadgeNumber = state => {
  const filteredBadge = state.user.active_badges?.filter(filterBadges) || [];
  if (filteredBadge.length > 0) {
    return filteredBadge[0];
  }
  return {};
};

export {
  checkInObject,
  flowId,
  flowPages,
  flowReady,
  getCurrentRouteName,
  hasValidBadge,
  isCheckedIn,
  offlineFlows,
  recentResults,
  isSubmittedOffline,
  selectedCampaignName,
  thankYouPageFields,
  userCampaigns,
  validBadgeNumber
};
