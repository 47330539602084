import { createActions, createReducer } from 'reduxsauce';

import { setInitial, updateProp } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    get: null,
    getError: null,
    set: ['payload', 'userId'],
    setCampaignsAndDefault: ['payload'],
    setSelectedCampaign: ['payload'],
    updateProp: ['key', 'value']
  },
  { prefix: 'campaign/' }
);

const initialState = {};

const initialUserState = {
  campaigns: []
};

export const set = (state, { userId, payload }) => {
  const newState = { ...state };
  if (!state[userId]) {
    newState[userId] = initialUserState;
  }
  newState[userId]['campaigns'] = payload;
  return newState;
};

export const setSelectedCampaign = (state, { userId, payload }) => {
  const newState = setInitial(state, userId, initialState);
  newState[userId]['selectedCampaign'] = payload;
  return newState;
};

export default createReducer(initialState, {
  [Types.UPDATE_PROP]: updateProp,
  [Types.SET]: set,
  [Types.SET_SELECTED_CAMPAIGN]: setSelectedCampaign
});
