import React from 'react';
import PropTypes from 'prop-types';
import { Block, BlockTitle, Icon, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { Button, Card, Fab } from 'Components';
import { NavigationService } from 'Services';

const Campaigns = ({
  availableCampaign,
  checkInObject,
  checkOut,
  flowId,
  flowReady,
  hasValidBadge,
  isCheckedIn,
  user
}) => {
  const renderMessage = () => (
    <Block>
      {isCheckedIn
        ? `${I18n.t('campaign:checkInMessage')} ${checkInObject.selected_postcodes}`
        : user.campaign && user.campaign.id == availableCampaign && hasValidBadge
          ? I18n.t('campaign:notCheckedInMessage')
          : !hasValidBadge
            ? I18n.t('campaign:noBadgeAssigned')
            : I18n.t('campaign:notAllowedMessage')}
    </Block>
  );

  return (
    <Page>
      <BlockTitle>{I18n.t('campaign:activity')}</BlockTitle>

      <Card.Notice>
        <RowView justifyContent={'space-between'}>
          {renderMessage()}
          <Block>
            {isCheckedIn ? (
              <Button.Transparent onClick={checkOut.bind(null, checkInObject.id)}>
                {I18n.t('campaign:checkOut')}
              </Button.Transparent>
            ) : (
              <Button.Transparent
                onClick={NavigationService.navigate.bind(null, {
                  name: 'CheckIn'
                })}>
                {I18n.t('campaign:checkIn')}
              </Button.Transparent>
            )}
          </Block>
        </RowView>
      </Card.Notice>

      <Fab
        disabled={!(isCheckedIn && flowReady)}
        onClick={NavigationService.navigate.bind(null, {
          name: 'Flow',
          flowId
        })}
        position={'right-bottom'}
        slot={'fixed'}>
        <Icon md={'material:play_arrow'}></Icon>
      </Fab>
    </Page>
  );
};

Campaigns.propTypes = {
  availableCampaign: PropTypes.string,
  badgeNumber: PropTypes.object,
  checkInObject: PropTypes.object,
  checkOut: PropTypes.func,
  flowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flowReady: PropTypes.bool,
  hasValidBadge: PropTypes.bool,
  isCheckedIn: PropTypes.bool,
  user: PropTypes.object
};

export default Campaigns;
