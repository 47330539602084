import { createActions } from 'reduxsauce';
import { persistCombineReducers } from 'redux-persist';

import { application, campaign, checkIn, flow, growl, postCodes, transient, result, user } from 'Reducers';
import { appInitialState } from 'Reducers/application';

import { storeConfig } from './config';

export const { Types } = createActions(
  {
    reset: null
  },
  { prefix: 'state/' }
);

const appReducers = persistCombineReducers(storeConfig, {
  application: application,
  campaign: campaign,
  checkIn: checkIn,
  flow: flow,
  growl: growl,
  postCodes: postCodes,
  transient: transient,
  result: result,
  user: user
});

const rootReducer = (state, action) => {
  if (action.type === 'state/RESET') {
    state = {
      ...state,
      application: {
        ...state.application,
        ...appInitialState
      },
      flow: undefined,
      transient: undefined,
      user: undefined
    };
  }
  return appReducers(state, action);
};

export default rootReducer;
