import { connect } from 'react-redux';

import { Creators as applicationActions } from 'Reducers/application';
import { Creators as transientActions } from 'Reducers/transient';

import Login from './view';

export default connect(
  state => ({
    client: state.application.client,
    isOffline: state.application.isOffline,
    mode: state.application.mode,
    orID: state.application.orID,
    serviceWorkerVersion: state.application.serviceWorkerVersion,
    transient: state.transient
  }),
  {
    donorLogin: applicationActions.donorLogin,
    login: applicationActions.login,
    updateProp: transientActions.updateProp,
    validateProp: transientActions.validateProp
  }
)(Login);
