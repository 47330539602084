import { createActions, createReducer } from 'reduxsauce';

import { updateProp } from 'Reducers/shared';

import { setInitial } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    addOfflineResult: ['userId', 'offlineResult'],
    get: null,
    getError: null,
    set: ['payload', 'userId'],
    spliceOfflineFlowById: ['userId', 'offlineFlow'],
    updateProp: ['key', 'value']
  },
  { prefix: 'result/' }
);

const initialState = {};

const initialUserState = {
  results: [],
  offline: []
};

export const addOfflineResult = (state, { userId, offlineResult }) => {
  let newState = { ...state };

  if (!newState[userId]?.offline) {
    newState = setInitial(state, userId, initialUserState);
  }

  newState[userId]['offline'].push(offlineResult);
  return { ...newState };
};

export const set = (state, { userId, payload }) => {
  const newState = setInitial(state, userId, initialUserState);
  newState[userId]['results'] = payload.recent_results;
  return { ...newState };
};

export const spliceOfflineFlowById = (state, { userId, offlineFlow }) => {
  const newArray = state[userId].offline.filter(item => item.id !== offlineFlow.id);

  return {
    ...state,
    [userId]: {
      ...state[userId],
      offline: [...newArray]
    }
  };
};

export default createReducer(initialState, {
  [Types.ADD_OFFLINE_RESULT]: addOfflineResult,
  [Types.SET]: set,
  [Types.SPLICE_OFFLINE_FLOW_BY_ID]: spliceOfflineFlowById,
  [Types.UPDATE_PROP]: updateProp
});
