import React from 'react';
import PropTypes from 'prop-types';
import { Link, Toolbar as ToolbarF7 } from 'framework7-react';

import { sum } from 'Helpers';
import { colors } from 'Theme';
import { I18n } from 'Locales';
import { Circle } from 'Images';
import { ToolBar } from 'Components';
import { RowView } from 'Containers';

import './style.scss';

class AppTopBar extends React.Component {
  availablePagesFilter = page => {
    const {
      flow: { availablePages }
    } = this.props;
    return availablePages.includes(page.id);
  };

  componentDidMount = () => {
    this.generateTopElementWidths();
  };

  componentDidUpdate = () => {
    this.generateTopElementWidths();
  };

  generateTopElementWidths = () => {
    const {
      currentRouteName,
      flow: { currentPageIndex }
    } = this.props;
    if (currentRouteName === 'Flow') {
      const filterNodeType = node => node.nodeName === 'A';
      this.topBarParent = document.querySelector('div.toolbar-inner');
      this.topElementWidths = Array.from(this.topBarParent.childNodes)
        .filter(filterNodeType)
        .map(el => el.offsetWidth);

      this.topElementWidths.length = currentPageIndex;
      const scrollLeft = this.topElementWidths.reduce(sum, 0);
      this.topBarParent.scroll({ left: scrollLeft, behavior: 'smooth' });
    }
  };

  paddingHorizontal = () => (this.props.currentRouteName === 'Flow' ? 0 : undefined);

  render = () =>
    !this.shouldHideToolbar() && (
      <ToolBar {...this.routeBasedToolBarProps()}>{this.renderRouteBasedToolBarContent()}</ToolBar>
    );

  renderDefaultTopBar = () => (
    <RowView justifyContent={'left'}>
      <Link iconMd={'material:menu'} panelOpen={'left'} />
      <RowView justifyContent={'flex-start'} paddingLeft={5}>
        <div className="defaultToolbarFont">{this.props.appTitle}</div>
      </RowView>
      {/* TODO show notifications bar <Link iconMd={'material:message'} panelOpen={'right'} /> */}
    </RowView>
  );

  renderFlowTopBar = () => {
    const {
      flow: { availablePages, currentPage, currentPageIndex, pages, validatedPages, signature }
    } = this.props;
    return (
      <ToolbarF7 position={'top'} tabbar scrollable noShadow noHairLine>
        {pages.filter(this.availablePagesFilter).map(page => (
          <Link className={'appTopBar-disable-riples'} key={page.id} tabLink tabLinkActive={page.id === currentPage.id}>
            <Circle
              fill={
                validatedPages.includes(page.id)
                  ? colors.success
                  : availablePages.includes(page.id)
                    ? colors.warning
                    : undefined
              }
            />
            {page.name}
          </Link>
        ))}

        <Link className={'appTopBar-disable-riples'} tabLink tabLinkActive={pages.length === currentPageIndex}>
          <Circle fill={signature?.agreement ? colors.success : colors.warning} />
          {I18n.t('flow:pages:summary')}
        </Link>
      </ToolbarF7>
    );
  };

  renderRouteBasedToolBarContent = () =>
    this.props.currentRouteName === 'Flow' ? this.renderFlowTopBar() : this.renderDefaultTopBar();

  routeBasedToolBarProps = () => ({
    scrollable: this.shouldScroll(),
    paddingHorizontal: this.paddingHorizontal()
  });

  shouldHideToolbar = () => {
    switch (this.props.currentRouteName) {
      case 'CheckIn':
      case 'ThankYou':
      case 'BrokenOrUnauthorized':
        return true;
      default:
        return false;
    }
  };

  shouldScroll = () => this.props.currentRouteName === 'Flow';
}

AppTopBar.propTypes = {
  appTitle: PropTypes.string,
  currentRouteName: PropTypes.string,
  flow: PropTypes.object
};

AppTopBar.defaultPropTypes = {
  appTitle: '',
  currentRouteName: '',
  flow: {}
};

export default AppTopBar;
