import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { colors } from 'Theme';
import { I18n } from 'Locales';
import { Avatar } from 'Components';
import { ColumnView, RowView } from 'Containers';

const AgentInfo = props => {
  const { flow_signature, userAvatar, userFullName, validBadgeNumber } = props;
  const { showBaFullName, showBaBadgeNumber, showBaPicture, showCurrentDate, legalStatement } = flow_signature;

  return (
    <div className="list">
      <RowView paddingLeft={15} paddingRight={15}>
        <ColumnView width={'auto'} flexGrow={4} flexBasis={null} justifyContent={'space-between'}>
          <RowView justifyContent={'flex-start'} flexWrap={'wrap'}>
            {showBaFullName && userFullName && (
              <ColumnView alignItems={'flex-start'} width={'auto'} paddingRight={10}>
                <div className="item-title item-label">{I18n.t('flow:components.agentInfo.brandAmbasador')}</div>

                <span className="inputValue">{userFullName}</span>
              </ColumnView>
            )}

            {showBaBadgeNumber && validBadgeNumber?.number && (
              <ColumnView alignItems={'flex-start'} width={'auto'}>
                <div className="item-title item-label">{I18n.t('flow:components.agentInfo.badgeNumber')}</div>

                <span>{validBadgeNumber?.number}</span>
              </ColumnView>
            )}

            {showCurrentDate && (
              <ColumnView alignItems={'flex-start'} width={'auto'}>
                <div className="item-title item-label">{I18n.t('flow:components.agentInfo.signDate')}</div>

                <span>{new Date(Date.now()).toLocaleString().split(',')[0]}</span>
              </ColumnView>
            )}
          </RowView>

          <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginBottom={10} />

          {legalStatement && legalStatement !== '' && (
            <Fragment>
              <RowView>
                <ColumnView alignItems={'flex-start'}>
                  <div className="item-title item-label">{I18n.t('flow:components.agentInfo.legalInfo')}</div>
                  <span>{legalStatement}</span>
                </ColumnView>
              </RowView>

              <RowView backgroundColor={colors.darkGrey} height={1} marginVertical={5} />
            </Fragment>
          )}
        </ColumnView>

        {showBaPicture && (
          <RowView flexGrow={1} width={'auto'} flexBasis={null}>
            <Avatar source={userAvatar} size={120} slot={'media'} />
          </RowView>
        )}
      </RowView>
    </div>
  );
};

AgentInfo.propTypes = {
  flow_signature: PropTypes.object,
  userAvatar: PropTypes.string,
  userFullName: PropTypes.string,
  validBadgeNumber: PropTypes.any
};

export default AgentInfo;
