import React from 'react';
import { ListInput } from 'framework7-react';

import { I18n } from 'Locales';
import { ColumnView, RowView } from 'Containers';

import { propTypes } from './shared';
import { getFieldProp } from '../../../shared';

import '../style.scss';

const FR = props => {
  const { value, setValues, shouldShowError, showExtraInfo2, fields } = props;

  let addressValues = value;
  if (!addressValues) {
    addressValues = {
      StreetName: '',
      PostCode: '',
      City: '',
      search_address: '',
      Number: '',
      Letter: '',
      ExtraInfo: '',
      ExtraInfo2: ''
    };
  }

  const numberProp = getFieldProp(fields, 'Number');
  const letterProp = getFieldProp(fields, 'Letter');
  const streetNameProp = getFieldProp(fields, 'StreetName');
  const extraInfoProp = getFieldProp(fields, 'ExtraInfo');
  const extraInfo2Prop = getFieldProp(fields, 'ExtraInfo2');
  const postCodeProp = getFieldProp(fields, 'PostCode');
  const cityProp = getFieldProp(fields, 'City');

  const { Number, Letter, StreetName, PostCode, City, ExtraInfo, ExtraInfo2 } = addressValues;
  return (
    <ColumnView>
      <RowView alignItems={'stretch'}>
        <ListInput
          label={I18n.t('flow:components.address.numberLabel')}
          type={'number'}
          placeholder={numberProp?.placeholder}
          value={Number}
          onChange={e => setValues(value, { Number: e.target.value })}
          className={'flex4'}
        />

        <ListInput
          label={I18n.t('flow:components.address.letterLabel')}
          type={'select'}
          placeholder={letterProp?.placeholder}
          value={Letter}
          onChange={e => setValues(value, { Letter: e.target.value })}>
          <option value={''}></option>
          <option value={'B'}>B</option>
          <option value={'T'}>T</option>
          <option value={'Q'}>Q</option>
        </ListInput>

        <ListInput
          label={I18n.t('flow:components.address.streetNameLabel')}
          type={'text'}
          placeholder={streetNameProp?.placeholder}
          value={StreetName}
          onChange={e => setValues(value, { StreetName: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && StreetName === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
      </RowView>

      <RowView>
        <ListInput
          label={I18n.t('flow:components.address.extraInfoLabel')}
          type={'text'}
          placeholder={extraInfoProp?.placeholder}
          value={ExtraInfo}
          onChange={e => setValues(value, { ExtraInfo: e.target.value })}
          className={'flex4'}
        />
      </RowView>

      {showExtraInfo2 && (
        <RowView>
          <ListInput
            label={I18n.t('flow:components.address.extraInfo2Label')}
            type={'text'}
            placeholder={extraInfo2Prop?.placeholder}
            value={ExtraInfo2}
            onChange={e => setValues(value, { ExtraInfo2: e.target.value })}
            className={'flex4'}
          />
        </RowView>
      )}

      <RowView>
        <ListInput
          label={I18n.t('flow:components.address.postalCodeLabel')}
          type={'text'}
          placeholder={postCodeProp?.placeholder}
          value={PostCode}
          onChange={e => setValues(value, { PostCode: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && PostCode === ''}
          errorMessage={I18n.t('flow:generalError')}
        />

        <ListInput
          label={I18n.t('flow:components.address.cityLabel')}
          type={'text'}
          placeholder={cityProp?.placeholder}
          value={City}
          onChange={e => setValues(value, { City: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && City === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
      </RowView>
    </ColumnView>
  );
};

FR.propTypes = propTypes;

export default FR;
