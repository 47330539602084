import { createActions, createReducer } from 'reduxsauce';

import { setInitial, updateProp } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    checkIn: null,
    checkOut: ['id'],
    get: null,
    getAddress: ['payload', 'userId'],
    getUKAddress: ['data'],
    getUKAddressSuccess: ['payload'],
    getUKAddressFail: null,
    set: ['payload', 'userId'],
    setAddress: ['payload', 'userId', 'checkInId'],
    updateProp: ['key', 'value']
  },
  { prefix: 'checkIn/' }
);

const initialState = {};

const initialCheckInsState = {
  checkIns: []
};

export const set = (state, { payload, userId }) => {
  const newState = setInitial(state, userId, initialCheckInsState);
  newState[userId]['checkIns'] = payload;
  return newState;
};

export const setAddress = (state, { payload, userId, checkInId }) => {
  let checkIndex;
  for (const [index, c] of state[userId].checkIns.entries()) {
    if (c.id === checkInId) {
      checkIndex = index;
    }
  }

  state[userId]['checkIns'][checkIndex].address = payload;

  return { ...state };
};

export default createReducer(initialState, {
  [Types.UPDATE_PROP]: updateProp,
  [Types.SET]: set,
  [Types.SET_ADDRESS]: setAddress
});
