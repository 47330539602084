import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { Creators as flowCreators } from 'Reducers/flow';
import { Creators as applicationActions } from 'Reducers/application';

import AppBottomBar from './view';

export default connect(
  state => {
    const flow = state.flow.flows[state.flow.currentFlowIndex];
    return {
      currentRouteName: Selectors.getCurrentRouteName(state),
      flow: {
        pages: flow?.flow_pages || [],
        currentPageIndex: state.flow.currentPageIndex
      },
      isOffline: state.application.isOffline,
      isSubmittedOffline: Selectors.isSubmittedOffline(state),
      lastTransactionId: state.application.lastTransactionId,
      mode: state.application.mode,
      offlineResults: Selectors.offlineFlows(state)
    };
  },
  {
    logout: applicationActions.logout,
    nextFlowPage: flowCreators.nextFlowPage,
    previousFlowPage: flowCreators.previousFlowPage
  }
)(AppBottomBar);
