import React from 'react';
import { Navbar, Panel, Page } from 'framework7-react';

import { I18n } from 'Locales';

const AppRightPanel = () => (
  <Panel right cover themeLight>
    <Page>
      <Navbar title={I18n.t('general:notifications')} />
    </Page>
  </Panel>
);

export default AppRightPanel;
