import React from 'react';
import PropTypes from 'prop-types';

import { BlockTitle, Page } from 'framework7-react';

import { I18n } from 'Locales';

const Results = ({ offlineResults, recentResults }) => (
  <Page>
    <BlockTitle>{I18n.t('result:title')}</BlockTitle>
    <table className="data-table">
      <thead>
        <tr>
          <th className="label-cell">{I18n.t('result:time')}</th>
          <th className="label-cell">{I18n.t('result:campaigns')}</th>
          <th className="label-cell">{I18n.t('result:status')}</th>
          <th className="numeric-cell">{I18n.t('result:transactionId')}</th>
        </tr>
      </thead>
      <tbody>
        {offlineResults.map(renderResultRow.bind(null))}
        {recentResults.map(renderResultRow.bind(null))}
      </tbody>
    </table>
  </Page>
);

const renderResultRow = item => (
  <tr key={item.id}>
    <td className="label-cell">{new Date(item.submitted_at).toLocaleString()}</td>
    <td className="label-cell">{item.campaign}</td>
    <td className="label-cell">{I18n.t(`result:state:${item.state}`)}</td>
    <td className="numeric-cell">{item.id}</td>
  </tr>
);

Results.propTypes = {
  recentResults: PropTypes.array,
  offlineResults: PropTypes.array
};

export default Results;
