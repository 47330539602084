import { put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { I18n } from 'Locales';
import { Result } from 'Repositories';
import { Types as GrowlTypes } from 'Reducers/growl';
import { user as userSelector } from 'Reducers/user';
import { Types as ResultTypes } from 'Reducers/result';

// EXPORTED
export const get = function*() {
  const user = yield select(userSelector);
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: ResultTypes.SET, userId: user.id },
      fail: { type: ResultTypes.GET_ERROR }
    },
    promise: Result.get()
  });
};

export const getError = function*() {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.result.get.title'),
    body: I18n.t('growl:error.result.get.body'),
    kind: 'error'
  });
};
