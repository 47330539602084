import { connect } from 'react-redux';

import Results from './view';

import * as Selectors from 'Selectors';

export default connect(
  state => ({
    offlineResults: Selectors.offlineFlows(state),
    recentResults: Selectors.recentResults(state)
  }),
  {}
)(Results);
