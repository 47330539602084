import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListInput } from 'framework7-react';

import { I18n } from 'Locales';
import { ibanMaskConfig } from 'Helpers';
import { ColumnView, RowView } from 'Containers';

import { getFieldProp } from '../../../shared';

class BankAccountFR extends Component {
  constructor(props) {
    super(props);
    this.registrationNumberProp = getFieldProp(this.props.fields, 'registrationNumber');
    this.bankNameProp = getFieldProp(this.props.fields, 'bankName');
    this.bicProp = getFieldProp(this.props.fields, 'bic');
  }

  componentDidUpdate = prevProps => {
    const { forceUpdate, updateValue, value: bankAccountValues } = this.props;
    if (forceUpdate !== prevProps.forceUpdate) {
      if (bankAccountValues.registrationNumber?.length > 0) {
        updateValue({
          value: {
            registrationNumber: bankAccountValues.registrationNumber,
            bic: '',
            bankName: '',
            validationStatus: '',
            validationMessage: ''
          },
          updatingKey: 'registrationNumber'
        });
      }
    }
  };

  render = () => {
    const { bankNameProp, bicProp, registrationNumberProp, props } = this;
    const { shouldShowError, updateValue, value } = props;

    let bankAccountValues = value;
    if (!bankAccountValues) {
      bankAccountValues = {
        bankName: '',
        bic: '',
        registrationNumber: ''
      };
    }

    const { bankName, bic, registrationNumber } = bankAccountValues;

    return (
      <ColumnView>
        <RowView>
          <ListInput
            label={I18n.t('flow:components.bankAccount.registrationNumber')}
            type={'text'}
            placeholder={registrationNumberProp?.placeholder}
            className={'flex1'}
            value={ibanMaskConfig(registrationNumber)}
            onChange={e =>
              updateValue({
                value: {
                  registrationNumber: e.target.value,
                  bic: '',
                  bankName: '',
                  validationStatus: '',
                  validationMessage: ''
                },
                updatingKey: 'registrationNumber'
              })
            }
            errorMessageForce={shouldShowError}
            errorMessage={I18n.t('flow:generalError')}
          />
        </RowView>

        {bankName && (
          <RowView>
            <ListInput
              label={I18n.t('flow:components.bankAccount.bankName')}
              type={'text'}
              placeholder={bankNameProp?.placeholder}
              className={'flex1'}
              value={bankName}
              disabled
            />
          </RowView>
        )}
        {bic && (
          <RowView>
            <ListInput
              label={I18n.t('flow:components.bankAccount.bic')}
              type={'text'}
              placeholder={bicProp?.placeholder}
              className={'flex1'}
              value={bic}
              disabled
            />
          </RowView>
        )}
      </ColumnView>
    );
  };
}

BankAccountFR.propTypes = {
  fields: PropTypes.array,
  forceUpdate: PropTypes.number,
  updateValue: PropTypes.func,
  value: PropTypes.any,
  shouldShowError: PropTypes.bool
};

export default BankAccountFR;
