import React from 'react';
import PropTypes from 'prop-types';
import { Card as F7Card, CardContent } from 'framework7-react';

import { colors } from 'Theme';
import { RowView } from 'Containers';

const Notice = props => <Card {...props} />;

const Card = props => (
  <F7Card outline>
    <CardContent padding={false}>
      <RowView
        backgroundColor={props.fill ? colors.lightGrey : ''}
        alignItems={'stretch'}
        justifyContent={'flex-start'}
      >
        {props.children}
      </RowView>
    </CardContent>
  </F7Card>
);

Card.propTypes = {
  children: PropTypes.object,
  fill: PropTypes.bool
};

export default { Notice };
