import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from 'framework7-react';

import { I18n } from 'Locales';

export const CheckInList = ({ postCodes, setSelectedPostcodes }) => (
  <List>
    <ListItem
      title={I18n.t('checkIn:postcode')}
      smartSelect
      smartSelectParams={{
        openIn: 'page',
        searchbar: true,
        searchbarPlaceholder: I18n.t('checkIn:searchPlaceholder'),
        virtualList: true,
        on: {
          smartSelectClose: e => {
            setSelectedPostcodes('selectedPostcodes', e.$selectEl.val());
          }
        }
      }}
    >
      <select name="postCodes" multiple>
        {postCodes.map(postCode => (
          <option key={postCode} value={postCode}>
            {postCode}
          </option>
        ))}
      </select>
    </ListItem>
  </List>
);

CheckInList.propTypes = {
  postCodes: PropTypes.array,
  setSelectedPostcodes: PropTypes.func
};

export default CheckInList;
