import React from 'react';
import PropTypes from 'prop-types';
import { Page as F7Page, Tabs, Tab } from 'framework7-react';

import { Page, Summary } from './subviews';

const availablePagesFilter = (availablePages, page) => availablePages.includes(page.id);

const Flow = props => {
  const {
    availablePages,
    currentCheckInAdress,
    currentFlowData,
    currentPage,
    currentPageIndex,
    flow_signature,
    getUKAddress,
    initSubmitFlow,
    invalidateComponent,
    isOffline,
    mode,
    name,
    pageIsInvalidOnNext,
    pages,
    processing,
    referenced_products,
    signature,
    transient,
    updateAgreement,
    updateCurrentFlowData,
    updateSignature,
    updateTransientProp,
    userAvatar,
    userFullName,
    validateComponent,
    validateOnlineBankAccount,
    validBadgeNumber
  } = props;

  return (
    <F7Page>
      {name ? (
        <Tabs>
          {pages.filter(availablePagesFilter.bind(null, availablePages)).map(page => (
            <Tab key={page.id} className="page-content" tabActive={page.id === currentPage?.id}>
              <Page
                key={page.id}
                {...page}
                currentFlowData={currentFlowData}
                currentPageIndex={currentPageIndex}
                referenced_products={referenced_products}
                updateCurrentFlowData={updateCurrentFlowData}
                getUKAddress={getUKAddress}
                updateTransientProp={updateTransientProp}
                isOffline={isOffline}
                transient={transient}
                currentCheckInAdress={currentCheckInAdress}
                invalidateComponent={invalidateComponent}
                validateComponent={validateComponent}
                validateOnlineBankAccount={validateOnlineBankAccount}
                pageIsInvalidOnNext={pageIsInvalidOnNext}
                userFullName={userFullName}
                mode={mode}
              />
            </Tab>
          ))}

          <Tab className="page-content" tabActive={pages.length === currentPageIndex}>
            <Summary
              currentFlowData={currentFlowData}
              currentPageIndex={currentPageIndex}
              flow_signature={flow_signature}
              initSubmitFlow={initSubmitFlow}
              isOffline={isOffline}
              pages={pages}
              processing={processing}
              referenced_products={referenced_products}
              signatureValues={signature}
              updateAgreement={updateAgreement}
              updateSignature={updateSignature}
              userAvatar={userAvatar}
              userFullName={userFullName}
              validBadgeNumber={validBadgeNumber}
              mode={mode}
            />
          </Tab>
        </Tabs>
      ) : null}
    </F7Page>
  );
};

Flow.propTypes = {
  availablePages: PropTypes.array,
  components: PropTypes.array,
  currentCheckInAdress: PropTypes.array,
  currentFlowData: PropTypes.object,
  currentPage: PropTypes.object,
  currentPageIndex: PropTypes.number,
  flow_signature: PropTypes.object,
  getUKAddress: PropTypes.func,
  initSubmitFlow: PropTypes.func,
  invalidateComponent: PropTypes.func,
  isOffline: PropTypes.bool,
  mode: PropTypes.string,
  name: PropTypes.string,
  pageIsInvalidOnNext: PropTypes.bool,
  pages: PropTypes.array,
  processing: PropTypes.bool,
  referenced_products: PropTypes.array,
  signature: PropTypes.object,
  transient: PropTypes.object,
  updateAgreement: PropTypes.func,
  updateCurrentFlowData: PropTypes.func,
  updateSignature: PropTypes.func,
  updateTransientProp: PropTypes.func,
  userAvatar: PropTypes.string,
  userFullName: PropTypes.string,
  validateComponent: PropTypes.func,
  validateOnlineBankAccount: PropTypes.func,
  validBadgeNumber: PropTypes.any
};

export default Flow;
