import { createActions, createReducer } from 'reduxsauce';

import { updateProp, updateProps } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    apiError: ['error'],
    checkOnline: null,
    backendLoginError: null,
    backendLoginSuccessful: ['payload'],
    brokenOrUnauthorizedAccess: null,
    checkDonorLoginCompleted: null,
    clearSession: null,
    closeSplashScreen: null,
    donorLogin: ['badge_number', 'orID'],
    donorLoginCompleted: ['flowId'],
    donorLoginError: null,
    donorLoginSuccessful: ['payload'],
    initFrameworkSeven: null,
    login: ['email', 'password'],
    logout: null,
    navigateTo: ['params'],
    navigateBack: null,
    onOrientationChange: null,
    saveUserPublicInfo: ['email', 'avatar'],
    persisted: null,
    routechanged: ['newRoute', 'previousRoute'],
    securityLogout: null,
    sessionExpiredLogout: null,
    updateProp: ['key', 'value'],
    updateProps: ['props'],
    userIsOnline: null
  },
  { prefix: 'application/' }
);

export const appInitialState = {
  isOffline: !window.navigator.onLine,
  offlineSince: null,
  routing: {
    history: [],
    historyParams: []
  },
  session: false,
  token: null,
  warnedSecurityLogout: false
};

const initialState = {
  country: 'default',
  initialised: false,
  lastKnownEmail: '',
  lastKnownAvatar: null,
  mode: 'agent',
  orientation: 'portrait',
  serviceWorkerVersion: 'NoSWV',
  ...appInitialState
};

export const navigateBack = state => {
  state.routing.history.splice(-1);
  state.routing.historyParams.splice(-1);
  return {
    ...state
  };
};

export const navigateTo = (state, { params: { name, params } }) => ({
  ...state,
  routing: {
    history: [...state.routing.history, name],
    historyParams: [...state.routing.historyParams, params]
  }
});

export const saveUserPublicInfo = (state, { email, avatar }) => ({
  ...state,
  lastKnownEmail: email,
  lastKnownAvatar: avatar
});

export default createReducer(initialState, {
  [Types.NAVIGATE_BACK]: navigateBack,
  [Types.NAVIGATE_TO]: navigateTo,
  [Types.SAVE_USER_PUBLIC_INFO]: saveUserPublicInfo,
  [Types.UPDATE_PROP]: updateProp,
  [Types.UPDATE_PROPS]: updateProps
});

export const ba = state => state.application.ba;
export const client = state => state.application.client;
export const isOffline = state => state.application.isOffline;
export const lastTransactionId = state => state.application.lastTransactionId;
export const lastKnownAvatar = state => state.application.lastKnownAvatar;
export const lastKnownEmail = state => state.application.lastKnownEmail;
export const mode = state => state.application.mode;
export const offlineSince = state => state.application.offlineSince;
export const orID = state => state.application.orID;
export const routing = state => state.application.routing;
export const session = state => state.application.session;
export const token = state => state.application.token;
export const warnedSecurityLogout = state => state.application.warnedSecurityLogout;
