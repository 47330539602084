import { createActions, createReducer } from 'reduxsauce';

import { updateProp, updateProps, validateProp } from '../shared';

export const { Types, Creators } = createActions(
  {
    reset: null,
    updateProp: ['key', 'value'],
    updateProps: ['props'],
    validateProp: ['prop']
  },
  { prefix: 'transient/' }
);

const initialState = {};

export const reset = () => initialState;

export default createReducer(initialState, {
  [Types.UPDATE_PROP]: updateProp,
  [Types.UPDATE_PROPS]: updateProps,
  [Types.VALIDATE_PROP]: validateProp,
  [Types.RESET]: reset
});
