import { connect } from 'react-redux';

import * as Selectors from 'Selectors';

import AppTopBar from './view';

export default connect(
  state => ({
    appTitle: Selectors.selectedCampaignName(state),
    currentRouteName: Selectors.getCurrentRouteName(state),
    flow: {
      pages: Selectors.flowPages(state),
      currentPage: state.flow.currentPage || {},
      currentPageIndex: state.flow.currentPageIndex,
      availablePages: state.flow.availablePages,
      validatedPages: state.flow.validatedPages,
      signature: state.flow.signature
    }
  }),
  {}
)(AppTopBar);
