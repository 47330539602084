import Config from 'Config';
import { Api } from 'Core';

const bankAccountCheckFR = registrationNumber =>
  Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/bank_services/validate_iban?iban=${registrationNumber}`);

const bankAccountCheckUK = (account_number, sort_code) =>
  Api.get(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/bank_services/validate_account_number` +
      `?account_number=${account_number}&sort_code=${sort_code}`
  );

const get = id => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/published_flows/${id}`);

export default { bankAccountCheckFR, bankAccountCheckUK, get };
