import PropTypes from 'prop-types';

import signatureStyle from './Signature/style';

const bankComponentOnlineFields = ['accountNumber', 'sortCode', 'registrationNumber', 'holderName'];

const componentPropTypes = {
  current_time: PropTypes.string,
  description: PropTypes.string,
  fields: PropTypes.array,
  id: PropTypes.string,
  logic_rules: PropTypes.array,
  name: PropTypes.string,
  properties: PropTypes.array,
  relationshipNames: PropTypes.array,
  shouldShowError: PropTypes.bool,
  type: PropTypes.string,
  updateValue: PropTypes.func
};

const getFieldProp = (fields, name) => fields.filter(f => f.field_type === name)[0];

export { bankComponentOnlineFields, componentPropTypes, getFieldProp, signatureStyle };
