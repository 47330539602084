import { createActions, createReducer } from 'reduxsauce';

import { updateProp } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    updateProp: ['key', 'value'],
    set: ['payload'],
    setDonorAddresses: ['payload']
  },
  { prefix: 'postCodes/' }
);

const initialState = {
  donorAddresses: [],
  list: [],
  selectedPostcodes: []
};

export const set = (state, { payload }) => ({
  ...state,
  list: [...new Set(payload)]
});

export const setDonorAddresses = (state, { payload }) => ({
  ...state,
  donorAddresses: [...new Set(payload)]
});

export default createReducer(initialState, {
  [Types.UPDATE_PROP]: updateProp,
  [Types.SET]: set,
  [Types.SET_DONOR_ADDRESSES]: setDonorAddresses
});

export const donorAddresses = state => state.postCodes.donorAddresses;
export const selectedPostcodes = state => state.postCodes.selectedPostcodes;
