import {
  BrokenOrUnauthorized,
  Campaigns,
  CheckIn,
  Flow,
  ForgotPassword,
  Login,
  NotFound,
  Results,
  ThankYou
} from 'Pages';

const publicRoutes = [
  {
    name: 'Login',
    path: '/public/user/login',
    component: Login
  },
  {
    name: 'ForgotPassword',
    path: '/public/user/forgot-password',
    component: ForgotPassword
  },
  {
    name: 'BrokenOrUnauthorized',
    path: '/public/broken-or-unauthorized',
    component: BrokenOrUnauthorized
  }
];

const sessionRoutes = [
  {
    name: 'Campaigns',
    path: '/session/campaigns',
    component: Campaigns,
    accessPermission: 'agent'
  },
  {
    name: 'CheckIn',
    path: '/session/checkin',
    component: CheckIn,
    accessPermission: 'agent'
  },
  {
    name: 'Flow',
    path: '/session/flow',
    component: Flow
  },
  {
    name: 'Results',
    path: '/session/results',
    component: Results,
    accessPermission: 'agent'
  },
  {
    name: 'ThankYou',
    path: '/session/thankyou',
    component: ThankYou
  }
];

const routes = [
  ...publicRoutes,
  ...sessionRoutes,
  {
    path: '(.*)',
    component: NotFound
  }
];

export const defaultRoutes = {
  donorSession: {
    name: 'Flow',
    url: '/session/flow'
  },
  noSession: {
    name: 'Login',
    url: '/public/user/login'
  },
  session: {
    name: 'Campaigns',
    url: '/session/campaigns'
  }
};

export default routes;
