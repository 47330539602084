import { connect } from 'react-redux';

import { Creators as postcodesActions } from 'Reducers/postCodes';
import { Creators as checkInActions } from 'Reducers/checkIn';

import CheckIn from './view';

export default connect(
  state => ({
    postCodes: state.postCodes.list
  }),
  {
    checkIn: checkInActions.checkIn,
    setSelectedPostcodes: postcodesActions.updateProp
  }
)(CheckIn);
