import React from 'react';
import { Provider } from 'react-redux';

import { store as generateStore } from 'Redux';
import App from './App';

const { store } = generateStore();

class Application extends React.Component {
  render = () => (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default Application;
