import React, { useRef } from 'react';
import PropType from 'prop-types';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { isSmallDevice } from 'Helpers';
import { ColumnView, RowView } from 'Containers';

import Signature from '../../shared/Signature';
import { componentPropTypes } from '../../shared';

const updateSign = (updateSignature, agreement, value) => {
  updateSignature('signature', {
    base64: value,
    agreement: agreement
  });
};

const SummarySignature = props => {
  const { currentPageIndex, description, mode, updateAgreement, updateSignature, userFullName, value } = props;

  const ref = useRef(null);

  return (
    <div ref={ref}>
      <ColumnView paddingLeft={16} marginBottom={15}>
        <RowView justifyContent="flex-start">
          <span>
            <b>{I18n.t(`flow:summary.${mode !== 'donor' ? 'customerSignature' : 'yourSignature'}`)}</b>
          </span>
        </RowView>

        <RowView flexWrap={isSmallDevice ? 'wrap' : 'nowrap'} justifyContent={'flex-start'}>
          <Signature
            currentPageIndex={currentPageIndex}
            updateSignature={updateSign.bind(null, updateSignature, value?.agreement)}
            value={value?.base64}
            description={description}
            parentRef={ref}
          />

          <ColumnView paddingLeft={4} justifyContent={'flex-start'}>
            <RowView justifyContent={'flex-start'}>
              <label>{description}</label>
            </RowView>

            {mode !== 'donor' && (
              <>
                <RowView backgroundColor={colors.darkGrey} height={1} marginVertical={10}></RowView>
                <ColumnView alignItems={'stretch'}>
                  <RowView justifyContent={'flex-start'}>
                    <span>
                      <b>{I18n.t('flow:summary.validity')}</b>
                    </span>
                  </RowView>

                  <RowView justifyContent={'flex-start'}>
                    <label className={`checkbox ${value?.base64 === '' ? 'disabled' : ''}`}>
                      <input
                        id="agreement-checkbox-summary"
                        type="checkbox"
                        disabled={value?.base64 === ''}
                        checked={value?.agreement}
                        onClick={updateAgreement}
                      />
                      <i className="icon-checkbox"></i>
                    </label>

                    <RowView paddingLeft={4} justifyContent={'flex-start'}>
                      <label htmlFor={'agreement-checkbox-summary'}>
                        {I18n.t('flow:summary.signatureValidity', { userFullName })}
                      </label>
                    </RowView>
                  </RowView>
                </ColumnView>
              </>
            )}
          </ColumnView>
        </RowView>
      </ColumnView>
    </div>
  );
};

SummarySignature.propTypes = {
  ...componentPropTypes,
  currentPageIndex: PropType.number,
  mode: PropType.string,
  updateAgreement: PropType.func,
  updateSignature: PropType.func,
  userFullName: PropType.string,
  values: PropType.object
};

export default SummarySignature;
