import React from 'react';
import { ListInput } from 'framework7-react';

import { I18n } from 'Locales';
import { ColumnView, RowView } from 'Containers';

import '../style.scss';
import { propTypes } from './shared';
import { getFieldProp } from '../../../shared';

const UK = props => {
  const { fields, isOffline, setValues, shouldShowError, transient, value } = props;
  const { disabled = true } = transient;
  const fieldDisabled = disabled && !isOffline;

  let addressValues = value;
  if (!addressValues) {
    addressValues = {
      AddressLine1: '',
      AddressLine2: '',
      AddressLine3: '',
      City: '',
      PostCode: '',
      search_address: ''
    };
  }

  const cityProp = getFieldProp(fields, 'City');
  const line1Prop = getFieldProp(fields, 'AddressLine1');
  const line2Prop = getFieldProp(fields, 'AddressLine2');
  const line3Prop = getFieldProp(fields, 'AddressLine3');
  const postCodeProp = getFieldProp(fields, 'PostCode');

  const { AddressLine1, AddressLine2, AddressLine3, City, PostCode } = addressValues;

  return (
    <ColumnView>
      <RowView alignItems={'stretch'}>
        <ListInput
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.line1')}
          type={'text'}
          placeholder={line1Prop?.placeholder}
          value={AddressLine1}
          onChange={e => setValues(value, { AddressLine1: e.target.value })}
          className={'fixedWidth'}
          errorMessageForce={shouldShowError && AddressLine1 === '' && AddressLine2 === '' && AddressLine3 === ''}
          errorMessage={I18n.t('flow:generalError')}
        />

        <ListInput
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.line2')}
          type={'text'}
          placeholder={line2Prop?.placeholder}
          value={AddressLine2}
          onChange={e => setValues(value, { AddressLine2: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && AddressLine1 === '' && AddressLine2 === '' && AddressLine3 === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
      </RowView>

      <RowView>
        <ListInput
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.line3')}
          type={'text'}
          placeholder={line3Prop?.placeholder}
          value={AddressLine3}
          onChange={e => setValues(value, { AddressLine3: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && AddressLine1 === '' && AddressLine2 === '' && AddressLine3 === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
      </RowView>

      <RowView>
        <ListInput
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.postalCodeLabel')}
          type={'text'}
          placeholder={postCodeProp?.placeholder}
          value={PostCode}
          onChange={e => setValues(value, { PostCode: e.target.value })}
          className={'fixedWidth'}
          errorMessageForce={shouldShowError && PostCode === ''}
          errorMessage={I18n.t('flow:generalError')}
        />

        <ListInput
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.cityLabel')}
          type={'text'}
          placeholder={cityProp?.placeholder}
          value={City}
          onChange={e => setValues(value, { City: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && City === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
      </RowView>
    </ColumnView>
  );
};

UK.propTypes = propTypes;

export default UK;
