import React from 'react';
import Config from 'Config';
import PropTypes from 'prop-types';
import { Block, BlockFooter, Col, List, ListInput, Page, Row } from 'framework7-react';

import { I18n } from 'Locales';
import { Avatar, Button, DynamicLogo } from 'Components';

import './style.scss';

const handleChange = (key, updateProp, validateProp, isDonor, e) => {
  updateProp(key, e.target.value);
  if (isDonor) {
    validateProp('enableDonorLogin');
  } else {
    validateProp('enableAgentLogin');
  }
};

const Login = ({
  client,
  donorLogin,
  isOffline,
  login,
  mode,
  orID,
  serviceWorkerVersion,
  transient,
  updateProp,
  validateProp
}) => {
  const isDonor = mode === 'donor';
  const avatarUrl = transient?.avatar ? transient.avatar : '';

  return (
    <Page noToolbar noNavbar noSwipeback loginScreen id={'login-page'}>
      <Col className="align-center">
        {!isDonor && <img src={'/static/images/data-byte-logo.png'} />}
        {isDonor && client && (
          <h3>
            <DynamicLogo name={client} height={60} />
          </h3>
        )}
      </Col>
      <Block className={transient?.error ? 'shake-form' : ''}>
        <Row>
          <Col width="25" className="align-center">
            {!isDonor && <Avatar source={avatarUrl} size="100" />}
            {isDonor && <img src={'/static/icons/lock_black.svg'} width={'100%'} height={'100%'} />}
          </Col>
          <Col width="75">
            {!isDonor && (
              <List form>
                <ListInput
                  label={I18n.t('inputs:email.label')}
                  type="email"
                  placeholder={I18n.t('inputs:email.placeholder')}
                  value={transient?.email || ''}
                  onChange={handleChange.bind(this, 'email', updateProp, validateProp, isDonor)}
                  autofocus
                  validate
                  validateOnBlur
                  required
                />

                <ListInput
                  label={I18n.t('inputs:password.label')}
                  type="password"
                  placeholder={I18n.t('inputs:password.placeholder')}
                  value={transient?.password || ''}
                  onChange={handleChange.bind(this, 'password', updateProp, validateProp, isDonor)}
                  validate
                  validateOnBlur
                  required
                />
              </List>
            )}
            {isDonor && (
              <List form>
                <ListInput
                  label={I18n.t('inputs:baBadge.label')}
                  type={'text'}
                  placeholder={I18n.t('inputs:baBadge.placeholder')}
                  value={transient?.badge_number || ''}
                  onChange={handleChange.bind(this, 'badge_number', updateProp, validateProp, isDonor)}
                  autofocus
                  validate
                  validateOnBlur
                  required
                />
              </List>
            )}
          </Col>
        </Row>

        <List>
          <Button.Primary
            disabled={
              (isDonor && !transient?.enableDonorLogin) || (!isDonor && !transient?.enableAgentLogin) || isOffline
            }
            processing={transient?.processing}
            onClick={
              isDonor
                ? donorLogin.bind(null, transient?.badge_number, orID)
                : login.bind(null, transient?.email, transient?.password)
            }>
            {I18n.t(`general:${isOffline ? 'offline_' : ''}${isDonor ? 'unlockFlow' : 'signIn'}`)}
          </Button.Primary>
          <BlockFooter>{I18n.t(`general:${isDonor ? 'donorLogin' : 'login'}Description`)}</BlockFooter>
          <BlockFooter>
            v: {Config.APP_VERSION}-{serviceWorkerVersion}
          </BlockFooter>
        </List>
      </Block>
    </Page>
  );
};

Login.propTypes = {
  client: PropTypes.string,
  donorLogin: PropTypes.func,
  isOffline: PropTypes.bool,
  login: PropTypes.func,
  mode: PropTypes.string,
  orID: PropTypes.string,
  serviceWorkerVersion: PropTypes.string,
  transient: PropTypes.object,
  user: PropTypes.object,
  updateProp: PropTypes.func,
  validateProp: PropTypes.func
};

export default Login;
