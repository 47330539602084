import React from 'react';
import { ListInput } from 'framework7-react';

import { I18n } from 'Locales';
import { ColumnView, RowView } from 'Containers';

import { propTypes } from './shared';
import { getFieldProp } from '../../../shared';

import '../style.scss';

const IE = props => {
  const { value, setValues, shouldShowError, fields } = props;

  let addressValues = value;
  if (!addressValues) {
    addressValues = {
      City: '',
      ExtraInfo: '',
      Number: '',
      PostalDistrict: '',
      PostCode: '',
      StreetName: ''
    };
  }

  const cityProp = getFieldProp(fields, 'City');
  const extraInfoProp = getFieldProp(fields, 'ExtraInfo');
  const numberProp = getFieldProp(fields, 'Number');
  const postalDistrict = getFieldProp(fields, 'PostalDistrict');
  const postCodeProp = getFieldProp(fields, 'PostCode');
  const streetNameProp = getFieldProp(fields, 'StreetName');

  const { Number, StreetName, PostalDistrict, PostCode, City, ExtraInfo } = addressValues;
  return (
    <ColumnView>
      <RowView alignItems={'stretch'}>
        <ListInput
          label={I18n.t('flow:components.address.numberLabel')}
          type={'number'}
          placeholder={numberProp?.placeholder}
          value={Number}
          onChange={e => setValues(value, { Number: e.target.value })}
          className={'flex4'}
        />

        <ListInput
          label={I18n.t('flow:components.address.streetNameLabel')}
          type={'text'}
          placeholder={streetNameProp?.placeholder}
          value={StreetName}
          onChange={e => setValues(value, { StreetName: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && StreetName === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
      </RowView>

      <RowView>
        <ListInput
          label={I18n.t('flow:components.address.extraInfoLabel')}
          type={'text'}
          placeholder={extraInfoProp?.placeholder}
          value={ExtraInfo}
          onChange={e => setValues(value, { ExtraInfo: e.target.value })}
          className={'flex4'}
        />
      </RowView>

      <RowView>
        <ListInput
          label={I18n.t('flow:components.address.postalDistrictLabel')}
          type={'text'}
          placeholder={postalDistrict?.placeholder}
          value={PostalDistrict}
          onChange={e => setValues(value, { PostalDistrict: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && PostalDistrict === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
        <ListInput
          label={I18n.t('flow:components.address.postalCodeLabel')}
          type={'text'}
          placeholder={postCodeProp?.placeholder}
          value={PostCode}
          onChange={e => setValues(value, { PostCode: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && PostCode === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
      </RowView>
      <RowView>
        <ListInput
          label={I18n.t('flow:components.address.cityLabel')}
          type={'text'}
          placeholder={cityProp?.placeholder}
          value={City}
          onChange={e => setValues(value, { City: e.target.value })}
          className={'flex4'}
          errorMessageForce={shouldShowError && City === ''}
          errorMessage={I18n.t('flow:generalError')}
        />
      </RowView>
    </ColumnView>
  );
};

IE.propTypes = propTypes;

export default IE;
