import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'framework7-react';

import { I18n } from 'Locales';
import { ToolBar } from 'Components';
import { ColumnView, RowView } from 'Containers';
import { F7, NavigationService } from 'Services';

import './style.scss';

class AppBottomBar extends React.Component {
  render = () => {
    switch (this.props.currentRouteName) {
      case 'Flow':
        return !this.shouldHideToolbar() && this.renderFlowBottomBar();
      case 'ThankYou':
        return !this.shouldHideToolbar() && this.renderThankYouBottomBar();
      default:
        return !this.shouldHideToolbar() && this.renderDefaultBottomBar();
    }
  };

  renderThankYouBottomBar = () => {
    const { isOffline, lastTransactionId, isSubmittedOffline } = this.props;
    return (
      <ToolBar>
        <span>
          <span className={`badge ${!isOffline ? 'color-blue' : ''}`}>{lastTransactionId}</span>{' '}
          {I18n.t(`result:${isSubmittedOffline ? 'successfullySavedOffline' : 'successfullySaved'}`)}
        </span>
        <Link iconMd={`material:${isOffline ? 'signal_wifi_off' : 'wifi'}`}>
          {I18n.t(`general:${isOffline ? 'offline' : 'online'}`)}
        </Link>
      </ToolBar>
    );
  };

  renderOfflineResults = () => {
    const { offlineResults } = this.props;

    return (
      <Link noLinkClass>
        <span className="badge color-red">{offlineResults.length}</span>
        <span> {I18n.t('general:offlineSales')}</span>
      </Link>
    );
  };

  renderDefaultBottomBar = () => {
    const { isOffline, offlineResults } = this.props;

    return (
      <ToolBar>
        {offlineResults.length > 0 ? this.renderOfflineResults() : <div />}
        <Link iconMd={`material:${isOffline ? 'signal_wifi_off' : 'wifi'}`} noLinkClass>
          {I18n.t(`general:${isOffline ? 'offline' : 'online'}`)}
        </Link>
      </ToolBar>
    );
  };

  renderExitFlowAlert = () => {
    const { logout, mode } = this.props;
    let title = I18n.t('flow:confirmExitFlow');
    let onClick = NavigationService.navigate.bind(null, { name: 'Campaigns' });
    if (mode === 'donor') {
      title = I18n.t('flow:confirmDonorExitFlow');
      onClick = logout;
    }

    F7.dialog
      .create({
        title,
        buttons: [
          {
            text: I18n.t('general:cancel')
          },
          {
            text: I18n.t('general:ok'),
            onClick
          }
        ]
      })
      .open();
  };

  navigateToPage = direction => {
    const { previousFlowPage, nextFlowPage } = this.props;

    if (direction === 'next') {
      nextFlowPage();
    } else if (direction === 'previous') {
      previousFlowPage();
    }
  };

  renderFlowBottomBar = () => {
    const {
      isOffline,
      flow: { pages, currentPageIndex }
    } = this.props;
    const totalPages = pages.length + 1;
    return (
      <ToolBar>
        <RowView justifyContent={'flex-start'}>
          <ColumnView alignItems={'flex-start'} width={'auto'} paddingHorizontal={16}>
            <Link
              text={I18n.t('general:previous')}
              className={'icon-size'}
              iconMd={'material:arrow_back'}
              disabled={currentPageIndex === 0}
              onClick={currentPageIndex !== 0 && totalPages > 1 && this.navigateToPage.bind(null, 'previous')}
            />
          </ColumnView>
          <ColumnView alignItems={'flex-start'} width={'auto'} paddingHorizontal={16}>
            <Link
              text={I18n.t('general:next')}
              className={'icon-size'}
              iconMd={'material:arrow_forward'}
              disabled={currentPageIndex === totalPages - 1}
              onClick={currentPageIndex !== totalPages - 1 && totalPages > 1 && this.navigateToPage.bind(null, 'next')}
            />
          </ColumnView>
        </RowView>
        <RowView justifyContent={'flex-end'}>
          <ColumnView alignItems={'flex-end'} width={'auto'} paddingRight={16}>
            <Link
              text={I18n.t('general:exit')}
              className={'icon-size'}
              iconMd={'material:exit_to_app'}
              onClick={this.renderExitFlowAlert}
            />
          </ColumnView>
          <ColumnView alignItems={'flex-end'} width={'auto'}>
            <Link iconMd={`material:${isOffline ? 'signal_wifi_off' : 'wifi'}`}>
              {I18n.t(`general:${isOffline ? 'offline' : 'online'}`)}
            </Link>
          </ColumnView>
        </RowView>
      </ToolBar>
    );
  };

  shouldHideToolbar = () => {
    switch (this.props.currentRouteName) {
      case 'BrokenOrUnauthorized':
      case 'ThankYou':
        return true;
      default:
        return false;
    }
  };
}

AppBottomBar.propTypes = {
  currentRouteName: PropTypes.string,
  flow: PropTypes.object,
  isOffline: PropTypes.bool,
  isSubmittedOffline: PropTypes.bool,
  lastTransactionId: PropTypes.string,
  logout: PropTypes.func,
  mode: PropTypes.string,
  nextFlowPage: PropTypes.func,
  offlineResults: PropTypes.array,
  previousFlowPage: PropTypes.func
};

AppBottomBar.defaultPropTypes = {
  currentRouteName: '',
  isOffline: false
};

export default AppBottomBar;
