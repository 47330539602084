import React from 'react';
import PropTypes from 'prop-types';
import { App as F7App } from 'framework7-react';

import Config from 'Config';
import { routes } from 'Core';
import { initF7 } from 'Services';
import { Growl } from 'Components';
import { ColumnView, FullView, SafeAreaView } from 'Containers';

import style from './style';

import { AppBottomBar, AppLeftPanel, AppRightPanel, AppTopBar, MainView, SplashScreen } from './subviews';

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      f7params: {
        name: Config.APP_NAME,
        theme: Config.APP_THEME,
        routes: routes,
        id: Config.APP_ID
      }
    };
  }

  componentDidMount = () => {
    this.$f7ready(this.initialiseF7);
  };

  initialiseF7 = f7 => {
    const { dispatch, initFrameworkSeven } = this.props;
    initF7(f7);
    dispatch(initFrameworkSeven());
  };

  render = () => {
    const { application } = this.props;
    return (
      <F7App params={{ ...this.state.f7params }}>
        <FullView id={Config.APP_ID} style={style.appWrapper}>
          <SafeAreaView>
            <ColumnView height={'100%'}>
              {application.session && <AppTopBar />}
              <MainView />
              <Growl />
              {application.mode === 'agent' && <AppLeftPanel />}
              {application.mode === 'agent' && <AppRightPanel />}
              {application.session && <AppBottomBar />}
            </ColumnView>
          </SafeAreaView>
        </FullView>
        {(!application.initialised || application.orientation === 'landscape') && (
          <FullView id={'SplashScreen'} style={style.splashScreen}>
            <SplashScreen orientation={application.orientation} />
          </FullView>
        )}
      </F7App>
    );
  };
}

App.propTypes = {
  application: PropTypes.object,
  clearSession: PropTypes.func,
  dispatch: PropTypes.func,
  logout: PropTypes.func,
  initFrameworkSeven: PropTypes.func
};

export default App;
