import React from 'react';
import PropTypes from 'prop-types';
import { Block, BlockTitle, Link, List, ListItem, Page, Panel, View } from 'framework7-react';

import Config from 'Config';
import { colors } from 'Theme';
import { I18n } from 'Locales';
import { Avatar } from 'Components';
import { NavigationService } from 'Services';
import { ColumnView, RowView, SafeAreaView } from 'Containers';

const AppLeftPanel = ({ campaigns, logout, user, setUserCampaign, serviceWorkerVersion }) => (
  <Panel left cover themeLight>
    <View>
      <Page>
        <SafeAreaView backgroundColor={colors.primary}>
          <ColumnView
            justifyContent={'space-between'}
            flex={1}
            height={'100%'}
            alignItems={'stretch'}
            backgroundColor={colors.standard}>
            <ColumnView alignItems={'stretch'}>
              <RowView
                minHeight={48}
                paddingHorizontal={15}
                justifyContent={'space-between'}
                backgroundColor={colors.lightGrey}>
                <Link
                  iconMd={'material:dashboard'}
                  className={'panel-close'}
                  onClick={NavigationService.navigate.bind(null, {
                    name: 'Campaigns'
                  })}
                />
              </RowView>

              <BlockTitle>{I18n.t('general:profile')}</BlockTitle>

              <RowView justifyContent={'space-between'} paddingLeft={15} paddingRight={9} minHeight={60}>
                <RowView flex={1} justifyContent={'flex-start'} minHeight={60}>
                  <Avatar source={user.avatar_url} size={48} slot={'media'} />
                  <ColumnView alignItems={'flex-start'} paddingLeft={5}>
                    {user.full_name}
                  </ColumnView>
                </RowView>
              </RowView>

              {campaigns && campaigns.length > 0 && <BlockTitle>{I18n.t('general:campaigns')}</BlockTitle>}

              {campaigns && campaigns.length > 0 && (
                <List>
                  {campaigns.map(item => (
                    <ListItem
                      title={item.name}
                      key={item.id}
                      radio
                      checked={user.campaign && item.id == user.campaign.id}
                      value={item.id}
                      onChange={setUserCampaign.bind(null, item)}
                      className={'panel-close'}
                    />
                  ))}
                </List>
              )}

              <BlockTitle>{I18n.t('general:tools')}</BlockTitle>

              <List>
                <ListItem
                  onClick={NavigationService.navigate.bind(null, {
                    name: 'Results'
                  })}
                  title={I18n.t('general:reports')}
                  className={'panel-close'}></ListItem>
                <ListItem onClick={logout} title={I18n.t('general:logout')} className={'panel-close'}></ListItem>
              </List>
            </ColumnView>
            <Block>
              v: {Config.APP_VERSION}-{serviceWorkerVersion}
            </Block>
          </ColumnView>
        </SafeAreaView>
      </Page>
    </View>
  </Panel>
);

AppLeftPanel.propTypes = {
  setUserCampaign: PropTypes.func,
  logout: PropTypes.func,
  user: PropTypes.object,
  campaigns: PropTypes.array,
  serviceWorkerVersion: PropTypes.string
};

AppLeftPanel.defaultProps = {
  logout: () => {},
  setUserCampaign: () => {},
  user: {},
  campaigns: []
};

export default AppLeftPanel;
