import { connect } from 'react-redux';

import ThankYou from './view';
import * as Selectors from 'Selectors';
import { Creators as applicationActions } from 'Reducers/application';

export default connect(
  state => ({
    fields: Selectors.thankYouPageFields(state),
    mode: state.application.mode
  }),
  {
    logout: applicationActions.logout
  }
)(ThankYou);
