import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { Creators as flowActions } from 'Reducers/flow';
import { Creators as checkInActions } from 'Reducers/checkIn';
import { Creators as transientActions } from 'Reducers/transient';

import Flow from './view';

export default connect(
  state => {
    const flow = state.flow.flows[state.flow.currentFlowIndex];
    const mode = state.application.mode;

    return {
      availablePages: state.flow.availablePages,
      components: flow?.flow_pages[state.flow.currentPageIndex]?.components,
      currentFlowData: state.flow.currentFlowData,
      currentCheckInAdress: mode === 'agent' ? Selectors.checkInObject(state)?.address : state.postCodes.donorAddresses,
      currentPage: state.flow.currentPage,
      currentPageIndex: state.flow.currentPageIndex,
      transient: state.transient,
      flow_signature: flow?.flow_signature,
      isOffline: state.application.isOffline,
      mode: state.application.mode,
      name: flow?.name,
      pages: Selectors.flowPages(state),
      pageIsInvalidOnNext: state.flow.pageIsInvalidOnNext,
      processing: state.flow.processing,
      referenced_products: flow?.referenced_products,
      signature: state.flow.signature,
      userAvatar: state.user.avatar_url,
      userFullName: state.user.full_name,
      validBadgeNumber: Selectors.validBadgeNumber(state)
    };
  },
  {
    getUKAddress: checkInActions.getUKAddress,
    initSubmitFlow: flowActions.initSubmitFlow,
    invalidateComponent: flowActions.invalidateComponent,
    updateAgreement: flowActions.updateAgreement,
    updateCurrentFlowData: flowActions.updateCurrentFlowData,
    updateSignature: flowActions.updateProp,
    updateTransientProp: transientActions.updateProp,
    validateComponent: flowActions.validateComponent,
    validateOnlineBankAccount: flowActions.validateOnlineBankAccount
  }
)(Flow);
