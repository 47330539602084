import { F7, dataFormatter } from 'Services';

const extraHeaders = {};

const Api = {
  API_CALL: 'API_CALL',
  API_ERROR: 'API_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',

  auth_token: token => {
    if (token) {
      extraHeaders['Authorization'] = `Token ${token}`;
    } else {
      delete extraHeaders['Authorization'];
    }
  },

  catchError: () => {}, //error

  testMinVersion() {
    // {headers}
    // if (headers && headers['x-min-version-code']) {
    //   if (
    //     parseInt(headers['x-min-version-code']) >
    //     parseInt(Config.APP_VERSION_CODE)
    //   ) {
    //     const { application } = store().store.getState();
    //     const lastRoute =
    //       application.stackRoute[application.stackRoute.length - 1];
    //     if (lastRoute !== 'UpgradeApp') {
    //       NavigationService.navigate({ routeName: 'UpgradeApp' });
    //     }
    //   }
    // }
  },

  f7promise: params => F7.request.promise(params),

  get(url) {
    const request = this.f7promise({
      url,
      method: 'GET',
      headers: extraHeaders
    });
    request.then(this.testMinVersion);
    request.catch(Api.catchError);
    return request;
  },

  post(url, data, shouldSerialize = true) {
    const request = this.f7promise({
      url,
      method: 'POST',
      data: shouldSerialize ? dataFormatter.serialize({ stuff: data }) : data,
      headers: extraHeaders
    });
    request.then(this.testMinVersion);
    request.catch(Api.catchError);
    return request;
  },

  put(url, data) {
    const request = this.f7promise({
      url,
      method: 'PUT',
      data: dataFormatter.serialize({ stuff: data }),
      headers: extraHeaders
    });
    request.then(this.testMinVersion);
    request.catch(Api.catchError);
    return request;
  },

  patch(url, data) {
    const request = this.f7promise({
      url,
      method: 'PATCH',
      data: dataFormatter.serialize({ stuff: data }),
      headers: extraHeaders
    });
    request.then(this.testMinVersion);
    request.catch(Api.catchError);
    return request;
  },

  delete(url) {
    const request = this.f7promise({
      url,
      method: 'DELETE',
      headers: extraHeaders
    });
    request.then(this.testMinVersion);
    request.catch(Api.catchError);
    return request;
  }
};

export default Api;
