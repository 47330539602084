import { connect } from 'react-redux';

import { Creators as applicationActions } from 'Reducers/application';

import App from './view';

export default connect(
  state => ({
    application: state.application
  }),
  dispatch => ({
    dispatch,
    clearSession: applicationActions.clearSession,
    initFrameworkSeven: applicationActions.initFrameworkSeven,
    logout: applicationActions.logout
  })
)(App);
