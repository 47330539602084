import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Avatar = props => {
  const [hasError, setHasError] = useState(false);
// TODO move the img below to an SVG based Icon component
  return hasError ? (
    <img className="avatar-img" src={'/static/icons/account_circle_black.svg'} width={'100%'} height={'100%'} />
  ) : (
    <img
      className="avatar-img"
      src={props.source}
      width={props.size + 'px'}
      height={props.size + 'px'}
      onError={setHasError.bind(null, true)}
    />
  );
};

Avatar.propTypes = {
  source: PropTypes.any,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Avatar.defaultProps = {
  source: '',
  size: '100'
};

export default Avatar;
