import Config from 'Config';
import { Api } from 'Core';

const getExecutionToken = () => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/users/execution_token`);

const login = (email, password) =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/sessions/?include=active_badges`,
    {
      session: {
        email,
        password
      }
    },
    false
  );

const donorLogin = (badge_number, token) =>
  Api.post(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/badges/validate`,
    {
      badge_number,
      token
    },
    false
  );

const updateUser = user =>
  Api.patch(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/users/${user.id}/?include=active_badges`, user);

export default { getExecutionToken, donorLogin, login, updateUser };
