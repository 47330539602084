import { createActions, createReducer } from 'reduxsauce';

import { updateProp, updateApiModel } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    setCampaign: ['campaign'],
    setUser: ['payload'],
    updateApiModel: ['props'],
    updateProp: ['key', 'value']
  },
  { prefix: 'user/' }
);

const initialState = {};

export const setUser = (state, { payload }) => {
  delete payload.auth_token;
  return {
    ...state,
    ...payload
  };
};

export default createReducer(initialState, {
  [Types.SET_USER]: setUser,
  [Types.UPDATE_API_MODEL]: updateApiModel,
  [Types.UPDATE_PROP]: updateProp
});

export const donorFlowId = state => state.user.published_flow_id;
export const firstName = state => state.user.first_name;
export const user = state => state.user;
